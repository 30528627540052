import React from "react"

import "./style.scss"

const GlassHiveLogoDark = () => {
  return (
    <div className="glasshive-logo-dark logo dark">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink" width="304.974" height="85.308" viewBox="0 0 304.974 85.308">
        <defs>
          <filter id="glasshive-logo-dark-a" x="80.729" y="17.854" width="53.811" height="54.78" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="b"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="b"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-c" x="120.32" y="17.854" width="23.863" height="54.78" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="d"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="d"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-e" x="129.721" y="30.452" width="42.52" height="42.084" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="f"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="f"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-g" x="157.506" y="30.724" width="35.523" height="42.054" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="h"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="h"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-i" x="177.131" y="30.724" width="35.523" height="42.054" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="j"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="j"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-k" x="197.222" y="16.739" width="47.705" height="54.78" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="l"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="l"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-m" x="230.464" y="20.034" width="24.009" height="52.648" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="n"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="n"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-o" x="238.798" y="30.646" width="41.915" height="41.939" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="p"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="p"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="glasshive-logo-dark-q" x="263.318" y="30.501" width="41.655" height="42.326" filterUnits="userSpaceOnUse">
            <feOffset dx="3" dy="5" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="r"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="r"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <linearGradient id="glasshive-logo-dark-s" x1="5.963" y1="4.061" x2="5.963" y2="4.061" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6370"/>
            <stop offset="1" stopColor="#f3c27e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-t" x1="5.946" y1="4.053" x2="5.946" y2="4.053" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6370"/>
            <stop offset="1" stopColor="#f3c17e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-u" x1="5.929" y1="4.044" x2="5.929" y2="4.044" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6371"/>
            <stop offset="1" stopColor="#f3c07e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-v" x1="5.913" y1="4.036" x2="5.913" y2="4.036" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6471"/>
            <stop offset="1" stopColor="#f3bf7f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-w" x1="5.896" y1="4.027" x2="5.896" y2="4.027" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6471"/>
            <stop offset="1" stopColor="#f3be7f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-x" x1="5.879" y1="4.019" x2="5.879" y2="4.019" xlinkHref="#w"/>
          <linearGradient id="glasshive-logo-dark-y" x1="5.862" y1="4.01" x2="5.862" y2="4.01" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6472"/>
            <stop offset="1" stopColor="#f3bd7f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-z" x1="5.845" y1="4.002" x2="5.845" y2="4.002" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6572"/>
            <stop offset="1" stopColor="#f3bc7f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-aa" x1="5.828" y1="3.993" x2="5.828" y2="3.993" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6572"/>
            <stop offset="1" stopColor="#f3bb7f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ab" x1="5.812" y1="3.985" x2="5.812" y2="3.985" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6573"/>
            <stop offset="1" stopColor="#f3ba80"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ac" x1="5.795" y1="3.976" x2="5.795" y2="3.976" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6573"/>
            <stop offset="1" stopColor="#f3b980"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ad" x1="5.778" y1="3.968" x2="5.778" y2="3.968" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6673"/>
            <stop offset="1" stopColor="#f3b880"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ae" x1="5.761" y1="3.959" x2="5.761" y2="3.959" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6673"/>
            <stop offset="1" stopColor="#f3b780"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-af" x1="5.744" y1="3.951" x2="5.744" y2="3.951" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6674"/>
            <stop offset="1" stopColor="#f3b680"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ag" x1="5.727" y1="3.943" x2="5.727" y2="3.942" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6674"/>
            <stop offset="1" stopColor="#f3b581"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ah" x1="5.71" y1="3.934" x2="5.71" y2="3.934" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6774"/>
            <stop offset="1" stopColor="#f3b581"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ai" x1="5.694" y1="3.926" x2="5.694" y2="3.925" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6774"/>
            <stop offset="1" stopColor="#f3b481"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-aj" x1="5.677" y1="3.917" x2="5.677" y2="3.917" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6775"/>
            <stop offset="1" stopColor="#f3b381"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ak" x1="5.66" y1="3.909" x2="5.66" y2="3.908" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6775"/>
            <stop offset="1" stopColor="#f3b281"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-al" x1="5.643" y1="3.9" x2="5.643" y2="3.9" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6775"/>
            <stop offset="1" stopColor="#f3b182"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-am" x1="5.626" y1="3.892" x2="5.626" y2="3.892" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6876"/>
            <stop offset="1" stopColor="#f3b082"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-an" x1="5.609" y1="3.883" x2="5.609" y2="3.883" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6876"/>
            <stop offset="1" stopColor="#f4af82"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ao" x1="5.593" y1="3.875" x2="5.593" y2="3.875" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6876"/>
            <stop offset="1" stopColor="#f4ae82"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ap" x1="5.576" y1="3.866" x2="5.576" y2="3.866" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6876"/>
            <stop offset="1" stopColor="#f4ad82"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-aq" x1="5.559" y1="3.858" x2="5.559" y2="3.858" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6977"/>
            <stop offset="1" stopColor="#f4ad82"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ar" x1="5.542" y1="3.849" x2="5.542" y2="3.849" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6977"/>
            <stop offset="1" stopColor="#f4ac83"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-as" x1="5.525" y1="3.841" x2="5.525" y2="3.841" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6977"/>
            <stop offset="1" stopColor="#f4ab83"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-at" x1="5.508" y1="3.832" x2="5.508" y2="3.832" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6978"/>
            <stop offset="1" stopColor="#f4aa83"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-au" x1="5.49" y1="3.824" x2="5.49" y2="3.824" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6a78"/>
            <stop offset="1" stopColor="#f4a983"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-av" x1="5.471" y1="3.816" x2="5.471" y2="3.815" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6a78"/>
            <stop offset="1" stopColor="#f4a883"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-aw" x1="5.454" y1="3.806" x2="5.454" y2="3.806" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6a78"/>
            <stop offset="1" stopColor="#f4a784"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ax" x1="5.438" y1="3.795" x2="5.438" y2="3.795" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6a79"/>
            <stop offset="1" stopColor="#f4a684"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ay" x1="5.422" y1="3.783" x2="5.422" y2="3.783" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6b79"/>
            <stop offset="1" stopColor="#f4a584"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-az" x1="5.408" y1="3.77" x2="5.408" y2="3.77" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6b79"/>
            <stop offset="1" stopColor="#f4a484"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ba" x1="5.396" y1="3.757" x2="5.396" y2="3.756" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6b7a"/>
            <stop offset="1" stopColor="#f4a484"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bb" x1="5.384" y1="3.742" x2="5.384" y2="3.742" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6b7a"/>
            <stop offset="1" stopColor="#f4a385"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bc" x1="5.373" y1="3.727" x2="5.373" y2="3.727" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6b7a"/>
            <stop offset="1" stopColor="#f4a285"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bd" x1="5.364" y1="3.711" x2="5.364" y2="3.711" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6c7a"/>
            <stop offset="1" stopColor="#f4a185"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-be" x1="5.356" y1="3.695" x2="5.356" y2="3.695" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6c7b"/>
            <stop offset="1" stopColor="#f4a085"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bf" x1="5.349" y1="3.678" x2="5.349" y2="3.678" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6c7b"/>
            <stop offset="1" stopColor="#f49f85"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bg" x1="5.343" y1="3.661" x2="5.343" y2="3.661" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6c7b"/>
            <stop offset="1" stopColor="#f49e85"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bh" x1="5.338" y1="3.644" x2="5.338" y2="3.644" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6d7b"/>
            <stop offset="1" stopColor="#f49d86"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bi" x1="5.335" y1="3.626" x2="5.335" y2="3.626" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6d7c"/>
            <stop offset="1" stopColor="#f49c86"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bj" x1="5.332" y1="3.608" x2="5.332" y2="3.608" xlinkHref="#bi"/>
          <linearGradient id="glasshive-logo-dark-bk" x1="5.331" y1="3.59" x2="5.331" y2="3.59" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6d7c"/>
            <stop offset="1" stopColor="#f49b86"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bl" x1="5.332" y1="3.572" x2="5.332" y2="3.572" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6e7d"/>
            <stop offset="1" stopColor="#f49a86"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bm" x1="5.333" y1="3.554" x2="5.333" y2="3.554" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6e7d"/>
            <stop offset="1" stopColor="#f49987"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bn" x1="5.336" y1="3.537" x2="5.336" y2="3.537" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6e7d"/>
            <stop offset="1" stopColor="#f49887"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bo" x1="5.34" y1="3.519" x2="5.34" y2="3.519" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6e7d"/>
            <stop offset="1" stopColor="#f49787"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bp" x1="5.345" y1="3.502" x2="5.345" y2="3.501" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6f7e"/>
            <stop offset="1" stopColor="#f49687"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bq" x1="5.351" y1="3.484" x2="5.351" y2="3.484" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6f7e"/>
            <stop offset="1" stopColor="#f49587"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-br" x1="5.359" y1="3.468" x2="5.359" y2="3.468" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6f7e"/>
            <stop offset="1" stopColor="#f49488"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bs" x1="5.368" y1="3.452" x2="5.368" y2="3.452" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe6f7f"/>
            <stop offset="1" stopColor="#f49388"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bt" x1="5.378" y1="3.436" x2="5.378" y2="3.436" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe707f"/>
            <stop offset="1" stopColor="#f49388"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bu" x1="5.39" y1="3.421" x2="5.39" y2="3.421" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe707f"/>
            <stop offset="1" stopColor="#f49288"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bv" x1="5.403" y1="3.407" x2="5.403" y2="3.407" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe707f"/>
            <stop offset="1" stopColor="#f49188"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bw" x1="5.417" y1="3.394" x2="5.417" y2="3.393" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7080"/>
            <stop offset="1" stopColor="#f49088"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bx" x1="5.432" y1="3.381" x2="5.432" y2="3.381" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7080"/>
            <stop offset="1" stopColor="#f48f89"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-by" x1="5.449" y1="3.369" x2="5.449" y2="3.369" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7180"/>
            <stop offset="1" stopColor="#f48e89"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-bz" x1="5.466" y1="3.359" x2="5.466" y2="3.359" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7180"/>
            <stop offset="1" stopColor="#f48d89"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ca" x1="5.483" y1="3.35" x2="5.483" y2="3.35" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7181"/>
            <stop offset="1" stopColor="#f48c89"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cb" x1="5.5" y1="3.341" x2="5.5" y2="3.341" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7181"/>
            <stop offset="1" stopColor="#f58b89"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cc" x1="5.516" y1="3.333" x2="5.516" y2="3.333" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7281"/>
            <stop offset="1" stopColor="#f58b8a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cd" x1="5.533" y1="3.324" x2="5.533" y2="3.324" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7282"/>
            <stop offset="1" stopColor="#f58a8a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ce" x1="5.55" y1="3.315" x2="5.55" y2="3.315" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7282"/>
            <stop offset="1" stopColor="#f5898a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cf" x1="5.566" y1="3.306" x2="5.566" y2="3.306" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7282"/>
            <stop offset="1" stopColor="#f5888a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cg" x1="5.583" y1="3.297" x2="5.583" y2="3.297" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7382"/>
            <stop offset="1" stopColor="#f5878a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ch" x1="5.6" y1="3.288" x2="5.6" y2="3.288" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7383"/>
            <stop offset="1" stopColor="#f5868a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ci" x1="5.616" y1="3.28" x2="5.616" y2="3.28" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7383"/>
            <stop offset="1" stopColor="#f5858b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cj" x1="5.633" y1="3.271" x2="5.633" y2="3.271" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7383"/>
            <stop offset="1" stopColor="#f5848b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ck" x1="5.65" y1="3.262" x2="5.65" y2="3.262" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7484"/>
            <stop offset="1" stopColor="#f5838b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cl" x1="5.666" y1="3.253" x2="5.666" y2="3.253" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7484"/>
            <stop offset="1" stopColor="#f5828b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cm" x1="5.683" y1="3.244" x2="5.683" y2="3.244" xlinkHref="#cl"/>
          <linearGradient id="glasshive-logo-dark-cn" x1="5.7" y1="3.235" x2="5.7" y2="3.235" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7484"/>
            <stop offset="1" stopColor="#f5818c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-co" x1="5.716" y1="3.227" x2="5.716" y2="3.226" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7485"/>
            <stop offset="1" stopColor="#f5808c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cp" x1="5.733" y1="3.218" x2="5.733" y2="3.218" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7585"/>
            <stop offset="1" stopColor="#f57f8c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cq" x1="5.75" y1="3.209" x2="5.75" y2="3.209" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7585"/>
            <stop offset="1" stopColor="#f57e8c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cr" x1="5.766" y1="3.2" x2="5.766" y2="3.2" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7586"/>
            <stop offset="1" stopColor="#f57d8c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cs" x1="5.783" y1="3.191" x2="5.783" y2="3.191" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7586"/>
            <stop offset="1" stopColor="#f57c8d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ct" x1="5.8" y1="3.182" x2="5.8" y2="3.182" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7686"/>
            <stop offset="1" stopColor="#f57b8d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cu" x1="5.816" y1="3.173" x2="5.816" y2="3.173" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe7686"/>
            <stop offset="1" stopColor="#f57a8d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cv" x1="5.833" y1="3.165" x2="5.833" y2="3.165" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7687"/>
            <stop offset="1" stopColor="#f57a8d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cw" x1="5.85" y1="3.156" x2="5.85" y2="3.156" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7687"/>
            <stop offset="1" stopColor="#f5798d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cx" x1="5.866" y1="3.147" x2="5.866" y2="3.147" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7787"/>
            <stop offset="1" stopColor="#f5788d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cy" x1="5.883" y1="3.138" x2="5.883" y2="3.138" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7787"/>
            <stop offset="1" stopColor="#f5778e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-cz" x1="5.9" y1="3.129" x2="5.9" y2="3.129" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78"/>
            <stop offset="1" stopColor="#f5768e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-da" x1="5.916" y1="3.12" x2="5.916" y2="3.12" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78"/>
            <stop offset="1" stopColor="#f5758e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-db" x1="5.933" y1="3.112" x2="5.933" y2="3.112" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7888"/>
            <stop offset="1" stopColor="#f5748e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dc" x1="5.95" y1="3.103" x2="5.95" y2="3.103" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7889"/>
            <stop offset="1" stopColor="#f5738e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dd" x1="5.966" y1="3.094" x2="5.966" y2="3.094" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7889"/>
            <stop offset="1" stopColor="#f5728f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-de" x1="5.983" y1="3.085" x2="5.983" y2="3.085" xlinkHref="#dd"/>
          <linearGradient id="glasshive-logo-dark-df" x1="6" y1="3.076" x2="6" y2="3.076" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7889"/>
            <stop offset="1" stopColor="#f5718f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dg" x1="6.016" y1="3.067" x2="6.016" y2="3.067" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff798a"/>
            <stop offset="1" stopColor="#f5708f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dh" x1="6.033" y1="3.059" x2="6.033" y2="3.058" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff798a"/>
            <stop offset="1" stopColor="#f56f8f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-di" x1="6.05" y1="3.05" x2="6.05" y2="3.05" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff798a"/>
            <stop offset="1" stopColor="#f56e90"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dj" x1="6.066" y1="3.041" x2="6.066" y2="3.041" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff798b"/>
            <stop offset="1" stopColor="#f56d90"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dk" x1="6.083" y1="3.032" x2="6.083" y2="3.032" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7a8b"/>
            <stop offset="1" stopColor="#f56c90"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dl" x1="6.1" y1="3.023" x2="6.1" y2="3.023" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7a8b"/>
            <stop offset="1" stopColor="#f56b90"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dm" x1="6.116" y1="3.014" x2="6.116" y2="3.014" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7a8b"/>
            <stop offset="1" stopColor="#f56a90"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dn" x1="6.133" y1="3.006" x2="6.133" y2="3.005" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7a8c"/>
            <stop offset="1" stopColor="#f56990"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-do" x1="6.15" y1="2.997" x2="6.15" y2="2.997" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7b8c"/>
            <stop offset="1" stopColor="#f56991"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dp" x1="6.166" y1="2.988" x2="6.166" y2="2.988" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7b8c"/>
            <stop offset="1" stopColor="#f66891"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dq" x1="6.183" y1="2.979" x2="6.183" y2="2.979" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7b8d"/>
            <stop offset="1" stopColor="#f66791"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dr" x1="6.2" y1="2.97" x2="6.2" y2="2.97" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7b8d"/>
            <stop offset="1" stopColor="#f66691"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ds" x1="6.216" y1="2.961" x2="6.216" y2="2.961" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7c8d"/>
            <stop offset="1" stopColor="#f66591"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dt" x1="6.233" y1="2.952" x2="6.233" y2="2.952" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7c8d"/>
            <stop offset="1" stopColor="#f66492"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-du" x1="6.25" y1="2.944" x2="6.25" y2="2.944" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7c8e"/>
            <stop offset="1" stopColor="#f66392"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dv" x1="6.266" y1="2.935" x2="6.266" y2="2.935" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7c8e"/>
            <stop offset="1" stopColor="#f66292"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dw" x1="6.283" y1="2.926" x2="6.283" y2="2.926" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7c8e"/>
            <stop offset="1" stopColor="#f66192"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dx" x1="6.3" y1="2.917" x2="6.3" y2="2.917" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7d8e"/>
            <stop offset="1" stopColor="#f66192"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dy" x1="6.316" y1="2.908" x2="6.316" y2="2.908" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7d8f"/>
            <stop offset="1" stopColor="#f66092"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-dz" x1="6.333" y1="2.899" x2="6.333" y2="2.899" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7d8f"/>
            <stop offset="1" stopColor="#f65f93"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ea" x1="6.349" y1="2.891" x2="6.349" y2="2.891" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7d8f"/>
            <stop offset="1" stopColor="#f65e93"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-eb" x1="6.366" y1="2.882" x2="6.366" y2="2.882" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7e90"/>
            <stop offset="1" stopColor="#f65d93"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ec" x1="6.383" y1="2.873" x2="6.383" y2="2.873" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7e90"/>
            <stop offset="1" stopColor="#f65c93"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ed" x1="6.399" y1="2.864" x2="6.399" y2="2.864" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7e90"/>
            <stop offset="1" stopColor="#f65b93"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ee" x1="6.416" y1="2.855" x2="6.416" y2="2.855" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7e90"/>
            <stop offset="1" stopColor="#f65a94"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ef" x1="6.433" y1="2.846" x2="6.433" y2="2.846" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7f91"/>
            <stop offset="1" stopColor="#f65994"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-eg" x1="6.449" y1="2.838" x2="6.449" y2="2.837" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7f91"/>
            <stop offset="1" stopColor="#f65894"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-eh" x1="6.466" y1="2.829" x2="6.466" y2="2.829" xlinkHref="#eg"/>
          <linearGradient id="glasshive-logo-dark-ei" x1="6.483" y1="2.82" x2="6.483" y2="2.82" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff7f92"/>
            <stop offset="1" stopColor="#f65794"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ej" x1="6.499" y1="2.811" x2="6.499" y2="2.811" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8092"/>
            <stop offset="1" stopColor="#f65695"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ek" x1="6.516" y1="2.802" x2="6.516" y2="2.802" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8092"/>
            <stop offset="1" stopColor="#f65595"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-el" x1="6.533" y1="2.793" x2="6.533" y2="2.793" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8092"/>
            <stop offset="1" stopColor="#f65495"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-em" x1="6.549" y1="2.784" x2="6.549" y2="2.784" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8093"/>
            <stop offset="1" stopColor="#f65395"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-en" x1="6.566" y1="2.776" x2="6.566" y2="2.776" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8193"/>
            <stop offset="1" stopColor="#f65295"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-eo" x1="6.583" y1="2.767" x2="6.583" y2="2.767" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8193"/>
            <stop offset="1" stopColor="#f65195"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ep" x1="6.599" y1="2.758" x2="6.599" y2="2.758" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8193"/>
            <stop offset="1" stopColor="#f65096"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-eq" x1="6.616" y1="2.749" x2="6.616" y2="2.749" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8194"/>
            <stop offset="1" stopColor="#f65096"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-er" x1="6.633" y1="2.74" x2="6.633" y2="2.74" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8194"/>
            <stop offset="1" stopColor="#f64f96"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-es" x1="6.65" y1="2.731" x2="6.65" y2="2.731" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8294"/>
            <stop offset="1" stopColor="#f64e96"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-et" x1="6.667" y1="2.723" x2="6.667" y2="2.723" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8295"/>
            <stop offset="1" stopColor="#f64d96"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-eu" x1="6.685" y1="2.716" x2="6.685" y2="2.716" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8295"/>
            <stop offset="1" stopColor="#f64c97"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ev" x1="6.702" y1="2.71" x2="6.702" y2="2.71" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8295"/>
            <stop offset="1" stopColor="#f64b97"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ew" x1="6.721" y1="2.705" x2="6.721" y2="2.705" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8395"/>
            <stop offset="1" stopColor="#f64a97"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ex" x1="6.739" y1="2.702" x2="6.739" y2="2.702" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8396"/>
            <stop offset="1" stopColor="#f64997"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ey" x1="6.758" y1="2.699" x2="6.758" y2="2.699" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8396"/>
            <stop offset="1" stopColor="#f64897"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ez" x1="6.776" y1="2.698" x2="6.776" y2="2.697" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8396"/>
            <stop offset="1" stopColor="#f64798"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fa" x1="6.795" y1="2.697" x2="6.795" y2="2.697" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8497"/>
            <stop offset="1" stopColor="#f64798"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fb" x1="6.814" y1="2.698" x2="6.814" y2="2.698" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8497"/>
            <stop offset="1" stopColor="#f64698"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fc" x1="6.832" y1="2.7" x2="6.832" y2="2.7" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8497"/>
            <stop offset="1" stopColor="#f64598"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fd" x1="6.851" y1="2.703" x2="6.851" y2="2.703" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8497"/>
            <stop offset="1" stopColor="#f74498"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fe" x1="6.869" y1="2.707" x2="6.869" y2="2.707" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8598"/>
            <stop offset="1" stopColor="#f74398"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ff" x1="6.887" y1="2.712" x2="6.887" y2="2.712" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8598"/>
            <stop offset="1" stopColor="#f74299"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fg" x1="6.905" y1="2.718" x2="6.905" y2="2.718" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8598"/>
            <stop offset="1" stopColor="#f74199"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fh" x1="6.923" y1="2.726" x2="6.923" y2="2.726" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8599"/>
            <stop offset="1" stopColor="#f74099"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fi" x1="6.94" y1="2.734" x2="6.94" y2="2.734" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8599"/>
            <stop offset="1" stopColor="#f73f99"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fj" x1="6.956" y1="2.744" x2="6.956" y2="2.744" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8699"/>
            <stop offset="1" stopColor="#f73f99"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fk" x1="6.973" y1="2.753" x2="6.973" y2="2.753" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff8699"/>
            <stop offset="1" stopColor="#f73e9a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fl" x1="6.989" y1="2.762" x2="6.989" y2="2.762" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff869a"/>
            <stop offset="1" stopColor="#f73d9a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fm" x1="7.005" y1="2.772" x2="7.005" y2="2.772" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff869a"/>
            <stop offset="1" stopColor="#f73c9a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fn" x1="7.022" y1="2.781" x2="7.022" y2="2.781" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff879a"/>
            <stop offset="1" stopColor="#f73b9a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fo" x1="7.038" y1="2.791" x2="7.038" y2="2.79" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff879a"/>
            <stop offset="1" stopColor="#f73a9a"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fp" x1="7.055" y1="2.8" x2="7.055" y2="2.8" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff879b"/>
            <stop offset="1" stopColor="#f7399b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fq" x1="7.071" y1="2.809" x2="7.071" y2="2.809" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff879b"/>
            <stop offset="1" stopColor="#f7389b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fr" x1="7.087" y1="2.819" x2="7.087" y2="2.819" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff889b"/>
            <stop offset="1" stopColor="#f7379b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fs" x1="7.104" y1="2.828" x2="7.104" y2="2.828" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff889c"/>
            <stop offset="1" stopColor="#f7369b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ft" x1="7.12" y1="2.837" x2="7.12" y2="2.837" xlinkHref="#fs"/>
          <linearGradient id="glasshive-logo-dark-fu" x1="7.136" y1="2.847" x2="7.136" y2="2.847" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff889c"/>
            <stop offset="1" stopColor="#f7359b"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fv" x1="7.153" y1="2.856" x2="7.153" y2="2.856" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff899c"/>
            <stop offset="1" stopColor="#f7349c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fw" x1="7.169" y1="2.865" x2="7.169" y2="2.865" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff899d"/>
            <stop offset="1" stopColor="#f7339c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fx" x1="7.186" y1="2.875" x2="7.186" y2="2.875" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff899d"/>
            <stop offset="1" stopColor="#f7329c"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fy" x1="7.202" y1="2.884" x2="7.202" y2="2.884" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff899e"/>
            <stop offset="1" stopColor="#f6329d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-fz" x1="7.218" y1="2.893" x2="7.218" y2="2.893" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff899e"/>
            <stop offset="1" stopColor="#f5339d"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ga" x1="7.235" y1="2.903" x2="7.235" y2="2.903" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe899f"/>
            <stop offset="1" stopColor="#f5339e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gb" x1="7.251" y1="2.912" x2="7.251" y2="2.912" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe899f"/>
            <stop offset="1" stopColor="#f4339e"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gc" x1="7.267" y1="2.921" x2="7.267" y2="2.921" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe89a0"/>
            <stop offset="1" stopColor="#f3339f"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gd" x1="7.284" y1="2.931" x2="7.284" y2="2.931" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe89a0"/>
            <stop offset="1" stopColor="#f234a0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ge" x1="7.3" y1="2.94" x2="7.3" y2="2.94" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe89a1"/>
            <stop offset="1" stopColor="#f234a0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gf" x1="7.316" y1="2.949" x2="7.316" y2="2.949" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fe89a1"/>
            <stop offset="1" stopColor="#f134a1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gg" x1="7.333" y1="2.959" x2="7.333" y2="2.959" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fd89a2"/>
            <stop offset="1" stopColor="#f034a2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gh" x1="7.349" y1="2.968" x2="7.349" y2="2.968" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fd89a2"/>
            <stop offset="1" stopColor="#ef35a2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gi" x1="7.365" y1="2.977" x2="7.365" y2="2.977" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fd89a3"/>
            <stop offset="1" stopColor="#ee35a3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gj" x1="7.382" y1="2.987" x2="7.382" y2="2.987" xlinkHref="#gi"/>
          <linearGradient id="glasshive-logo-dark-gk" x1="7.398" y1="2.996" x2="7.398" y2="2.996" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fd89a4"/>
            <stop offset="1" stopColor="#ed35a4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gl" x1="7.414" y1="3.005" x2="7.414" y2="3.005" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fd89a4"/>
            <stop offset="1" stopColor="#ec36a5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gm" x1="7.43" y1="3.015" x2="7.43" y2="3.015" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fc89a5"/>
            <stop offset="1" stopColor="#eb36a5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gn" x1="7.447" y1="3.024" x2="7.447" y2="3.024" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fc89a5"/>
            <stop offset="1" stopColor="#eb36a6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-go" x1="7.463" y1="3.033" x2="7.463" y2="3.033" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fc89a6"/>
            <stop offset="1" stopColor="#ea36a6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gp" x1="7.479" y1="3.043" x2="7.479" y2="3.043" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fc89a6"/>
            <stop offset="1" stopColor="#e937a7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gq" x1="7.496" y1="3.052" x2="7.496" y2="3.052" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fc89a7"/>
            <stop offset="1" stopColor="#e837a8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gr" x1="7.512" y1="3.061" x2="7.512" y2="3.061" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fc89a7"/>
            <stop offset="1" stopColor="#e737a8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gs" x1="7.528" y1="3.071" x2="7.528" y2="3.071" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fb8aa8"/>
            <stop offset="1" stopColor="#e737a9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gt" x1="7.545" y1="3.08" x2="7.545" y2="3.08" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fb8aa8"/>
            <stop offset="1" stopColor="#e638aa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gu" x1="7.561" y1="3.09" x2="7.561" y2="3.089" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fb8aa9"/>
            <stop offset="1" stopColor="#e538aa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gv" x1="7.577" y1="3.099" x2="7.577" y2="3.099" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fb8aa9"/>
            <stop offset="1" stopColor="#e438ab"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gw" x1="7.594" y1="3.108" x2="7.594" y2="3.108" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fb8aaa"/>
            <stop offset="1" stopColor="#e438ab"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gx" x1="7.61" y1="3.118" x2="7.61" y2="3.117" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fa8aaa"/>
            <stop offset="1" stopColor="#e339ac"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gy" x1="7.626" y1="3.127" x2="7.626" y2="3.127" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fa8aab"/>
            <stop offset="1" stopColor="#e239ad"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-gz" x1="7.643" y1="3.136" x2="7.643" y2="3.136" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fa8aab"/>
            <stop offset="1" stopColor="#e139ad"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ha" x1="7.659" y1="3.146" x2="7.659" y2="3.145" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fa8aac"/>
            <stop offset="1" stopColor="#e039ae"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hb" x1="7.675" y1="3.155" x2="7.675" y2="3.155" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fa8aac"/>
            <stop offset="1" stopColor="#e03aae"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hc" x1="7.692" y1="3.164" x2="7.692" y2="3.164" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fa8aad"/>
            <stop offset="1" stopColor="#df3aaf"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hd" x1="7.708" y1="3.174" x2="7.708" y2="3.174" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f98aad"/>
            <stop offset="1" stopColor="#de3ab0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-he" x1="7.724" y1="3.183" x2="7.724" y2="3.183" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f98aae"/>
            <stop offset="1" stopColor="#dd3ab0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hf" x1="7.741" y1="3.192" x2="7.741" y2="3.192" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f98aae"/>
            <stop offset="1" stopColor="#dd3bb1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hg" x1="7.757" y1="3.202" x2="7.757" y2="3.202" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f98aaf"/>
            <stop offset="1" stopColor="#dc3bb2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hh" x1="7.773" y1="3.211" x2="7.773" y2="3.211" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f98aaf"/>
            <stop offset="1" stopColor="#db3bb2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hi" x1="7.79" y1="3.22" x2="7.79" y2="3.22" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f98ab0"/>
            <stop offset="1" stopColor="#da3bb3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hj" x1="7.806" y1="3.23" x2="7.806" y2="3.23" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f88ab0"/>
            <stop offset="1" stopColor="#d93cb3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hk" x1="7.822" y1="3.239" x2="7.822" y2="3.239" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f88ab1"/>
            <stop offset="1" stopColor="#d93cb4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hl" x1="7.839" y1="3.248" x2="7.839" y2="3.248" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f88ab1"/>
            <stop offset="1" stopColor="#d83cb5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hm" x1="7.855" y1="3.258" x2="7.855" y2="3.258" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f88ab2"/>
            <stop offset="1" stopColor="#d73cb5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hn" x1="7.871" y1="3.267" x2="7.871" y2="3.267" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f88ab2"/>
            <stop offset="1" stopColor="#d63db6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ho" x1="7.888" y1="3.276" x2="7.888" y2="3.276" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f88ab3"/>
            <stop offset="1" stopColor="#d63db6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hp" x1="7.904" y1="3.286" x2="7.904" y2="3.286" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78ab3"/>
            <stop offset="1" stopColor="#d53db7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hq" x1="7.92" y1="3.295" x2="7.92" y2="3.295" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78ab4"/>
            <stop offset="1" stopColor="#d43db8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hr" x1="7.937" y1="3.304" x2="7.937" y2="3.304" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78ab4"/>
            <stop offset="1" stopColor="#d33eb8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hs" x1="7.953" y1="3.314" x2="7.953" y2="3.314" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78ab5"/>
            <stop offset="1" stopColor="#d33eb9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ht" x1="7.969" y1="3.323" x2="7.969" y2="3.323" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f78ab5"/>
            <stop offset="1" stopColor="#d23eba"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hu" x1="7.986" y1="3.332" x2="7.986" y2="3.332" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f68ab6"/>
            <stop offset="1" stopColor="#d13eba"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hv" x1="8.002" y1="3.342" x2="8.002" y2="3.342" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f68ab6"/>
            <stop offset="1" stopColor="#d03fbb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hw" x1="8.018" y1="3.351" x2="8.018" y2="3.351" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f68ab7"/>
            <stop offset="1" stopColor="#cf3fbb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hx" x1="8.035" y1="3.36" x2="8.035" y2="3.36" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f68ab7"/>
            <stop offset="1" stopColor="#cf3fbc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hy" x1="8.051" y1="3.37" x2="8.051" y2="3.37" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f68ab8"/>
            <stop offset="1" stopColor="#ce3fbd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-hz" x1="8.067" y1="3.379" x2="8.067" y2="3.379" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f68ab8"/>
            <stop offset="1" stopColor="#cd40bd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ia" x1="8.084" y1="3.388" x2="8.084" y2="3.388" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f58ab9"/>
            <stop offset="1" stopColor="#cc40be"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ib" x1="8.1" y1="3.398" x2="8.1" y2="3.398" xlinkHref="#ia"/>
          <linearGradient id="glasshive-logo-dark-ic" x1="8.116" y1="3.408" x2="8.116" y2="3.408" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f58aba"/>
            <stop offset="1" stopColor="#cb41bf"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-id" x1="8.132" y1="3.419" x2="8.132" y2="3.419" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f58aba"/>
            <stop offset="1" stopColor="#ca41c0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ie" x1="8.147" y1="3.431" x2="8.147" y2="3.431" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f58abb"/>
            <stop offset="1" stopColor="#c941c0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-if" x1="8.16" y1="3.443" x2="8.16" y2="3.443" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f58abb"/>
            <stop offset="1" stopColor="#c841c1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ig" x1="8.174" y1="3.457" x2="8.174" y2="3.457" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f48bbc"/>
            <stop offset="1" stopColor="#c842c2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ih" x1="8.186" y1="3.471" x2="8.186" y2="3.471" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f48bbc"/>
            <stop offset="1" stopColor="#c742c2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ii" x1="8.197" y1="3.485" x2="8.197" y2="3.485" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f48bbd"/>
            <stop offset="1" stopColor="#c642c3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ij" x1="8.207" y1="3.501" x2="8.207" y2="3.501" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f48bbd"/>
            <stop offset="1" stopColor="#c542c3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ik" x1="8.217" y1="3.517" x2="8.217" y2="3.517" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f48bbe"/>
            <stop offset="1" stopColor="#c543c4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-il" x1="8.225" y1="3.533" x2="8.225" y2="3.533" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f48bbe"/>
            <stop offset="1" stopColor="#c443c5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-im" x1="8.232" y1="3.55" x2="8.232" y2="3.55" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f38bbf"/>
            <stop offset="1" stopColor="#c343c5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-in" x1="8.239" y1="3.568" x2="8.239" y2="3.568" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f38bbf"/>
            <stop offset="1" stopColor="#c243c6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-io" x1="8.244" y1="3.586" x2="8.244" y2="3.585" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f38bc0"/>
            <stop offset="1" stopColor="#c144c6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ip" x1="8.248" y1="3.604" x2="8.248" y2="3.604" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f38bc0"/>
            <stop offset="1" stopColor="#c144c7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-iq" x1="8.252" y1="3.622" x2="8.252" y2="3.622" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f38bc1"/>
            <stop offset="1" stopColor="#c044c8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ir" x1="8.254" y1="3.641" x2="8.254" y2="3.641" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f28bc1"/>
            <stop offset="1" stopColor="#bf44c8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-is" x1="8.254" y1="3.66" x2="8.254" y2="3.66" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f28bc2"/>
            <stop offset="1" stopColor="#be45c9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-it" x1="8.254" y1="3.679" x2="8.254" y2="3.679" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f28bc2"/>
            <stop offset="1" stopColor="#be45ca"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-iu" x1="8.254" y1="3.697" x2="8.254" y2="3.697" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f28bc3"/>
            <stop offset="1" stopColor="#bd45ca"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-iv" x1="8.254" y1="3.716" x2="8.254" y2="3.716" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f28bc3"/>
            <stop offset="1" stopColor="#bc45cb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-iw" x1="8.254" y1="3.735" x2="8.254" y2="3.735" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f28bc4"/>
            <stop offset="1" stopColor="#bb46cb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ix" x1="8.254" y1="3.753" x2="8.254" y2="3.753" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f18bc4"/>
            <stop offset="1" stopColor="#ba46cc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-iy" x1="8.254" y1="3.772" x2="8.254" y2="3.772" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f18bc5"/>
            <stop offset="1" stopColor="#ba46cd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-iz" x1="8.254" y1="3.791" x2="8.254" y2="3.791" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f18bc5"/>
            <stop offset="1" stopColor="#b946cd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ja" x1="8.254" y1="3.809" x2="8.254" y2="3.809" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f18bc6"/>
            <stop offset="1" stopColor="#b847ce"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jb" x1="8.254" y1="3.828" x2="8.254" y2="3.828" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f18bc6"/>
            <stop offset="1" stopColor="#b747ce"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jc" x1="8.254" y1="3.847" x2="8.254" y2="3.847" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f18bc7"/>
            <stop offset="1" stopColor="#b747cf"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jd" x1="8.254" y1="3.865" x2="8.254" y2="3.865" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f08bc7"/>
            <stop offset="1" stopColor="#b647d0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-je" x1="8.254" y1="3.884" x2="8.254" y2="3.884" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f08bc8"/>
            <stop offset="1" stopColor="#b548d0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jf" x1="8.254" y1="3.903" x2="8.254" y2="3.903" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f08bc8"/>
            <stop offset="1" stopColor="#b448d1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jg" x1="8.254" y1="3.922" x2="8.254" y2="3.921" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f08bc9"/>
            <stop offset="1" stopColor="#b348d1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jh" x1="8.254" y1="3.94" x2="8.254" y2="3.94" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f08bc9"/>
            <stop offset="1" stopColor="#b348d2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ji" x1="8.254" y1="3.959" x2="8.254" y2="3.959" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f08bca"/>
            <stop offset="1" stopColor="#b249d3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jj" x1="8.254" y1="3.978" x2="8.254" y2="3.977" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ef8bca"/>
            <stop offset="1" stopColor="#b149d3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jk" x1="8.254" y1="3.996" x2="8.254" y2="3.996" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ef8bcb"/>
            <stop offset="1" stopColor="#b049d4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jl" x1="8.254" y1="4.015" x2="8.254" y2="4.015" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ef8bcb"/>
            <stop offset="1" stopColor="#b049d5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jm" x1="8.254" y1="4.034" x2="8.254" y2="4.034" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ef8bcc"/>
            <stop offset="1" stopColor="#af4ad5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jn" x1="8.254" y1="4.052" x2="8.254" y2="4.052" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ef8bcc"/>
            <stop offset="1" stopColor="#ae4ad6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jo" x1="8.254" y1="4.071" x2="8.254" y2="4.071" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ee8bcd"/>
            <stop offset="1" stopColor="#ad4ad6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jp" x1="8.254" y1="4.09" x2="8.254" y2="4.09" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ee8bcd"/>
            <stop offset="1" stopColor="#ac4ad7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jq" x1="8.254" y1="4.108" x2="8.254" y2="4.108" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ee8bce"/>
            <stop offset="1" stopColor="#ac4bd8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jr" x1="8.254" y1="4.127" x2="8.254" y2="4.127" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ee8bce"/>
            <stop offset="1" stopColor="#ab4bd8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-js" x1="8.254" y1="4.146" x2="8.254" y2="4.146" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ee8bcf"/>
            <stop offset="1" stopColor="#aa4bd9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jt" x1="8.254" y1="4.164" x2="8.254" y2="4.164" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ee8bcf"/>
            <stop offset="1" stopColor="#a94bd9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ju" x1="8.254" y1="4.183" x2="8.254" y2="4.183" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ed8cd0"/>
            <stop offset="1" stopColor="#a94cda"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jv" x1="8.254" y1="4.202" x2="8.254" y2="4.202" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ed8cd0"/>
            <stop offset="1" stopColor="#a84cdb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jw" x1="8.254" y1="4.22" x2="8.254" y2="4.22" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ed8cd1"/>
            <stop offset="1" stopColor="#a74cdb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jx" x1="8.254" y1="4.239" x2="8.254" y2="4.239" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ed8cd1"/>
            <stop offset="1" stopColor="#a64cdc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jy" x1="8.254" y1="4.258" x2="8.254" y2="4.258" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ed8cd2"/>
            <stop offset="1" stopColor="#a54ddd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-jz" x1="8.254" y1="4.276" x2="8.254" y2="4.276" xlinkHref="#jy"/>
          <linearGradient id="glasshive-logo-dark-ka" x1="8.254" y1="4.295" x2="8.254" y2="4.295" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ec8cd3"/>
            <stop offset="1" stopColor="#a44dde"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kb" x1="8.254" y1="4.314" x2="8.254" y2="4.314" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ec8cd3"/>
            <stop offset="1" stopColor="#a34ede"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kc" x1="8.254" y1="4.332" x2="8.254" y2="4.332" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ec8cd4"/>
            <stop offset="1" stopColor="#a24edf"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kd" x1="8.254" y1="4.351" x2="8.254" y2="4.351" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ec8cd4"/>
            <stop offset="1" stopColor="#a24ee0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ke" x1="8.254" y1="4.37" x2="8.254" y2="4.37" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ec8cd5"/>
            <stop offset="1" stopColor="#a14ee0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kf" x1="8.254" y1="4.388" x2="8.254" y2="4.388" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ec8cd5"/>
            <stop offset="1" stopColor="#a04fe1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kg" x1="8.254" y1="4.407" x2="8.254" y2="4.407" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#eb8cd6"/>
            <stop offset="1" stopColor="#9f4fe1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kh" x1="8.254" y1="4.426" x2="8.254" y2="4.426" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#eb8cd6"/>
            <stop offset="1" stopColor="#9e4fe2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ki" x1="8.254" y1="4.444" x2="8.254" y2="4.444" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#eb8cd7"/>
            <stop offset="1" stopColor="#9e4fe3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kj" x1="8.254" y1="4.463" x2="8.254" y2="4.463" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#eb8cd7"/>
            <stop offset="1" stopColor="#9d50e3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kk" x1="8.254" y1="4.482" x2="8.254" y2="4.482" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#eb8cd8"/>
            <stop offset="1" stopColor="#9c50e4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kl" x1="8.254" y1="4.5" x2="8.254" y2="4.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ea8cd8"/>
            <stop offset="1" stopColor="#9b50e5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-km" x1="8.254" y1="4.519" x2="8.254" y2="4.519" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ea8cd9"/>
            <stop offset="1" stopColor="#9b50e5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kn" x1="8.254" y1="4.538" x2="8.254" y2="4.538" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ea8cd9"/>
            <stop offset="1" stopColor="#9a51e6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ko" x1="8.254" y1="4.557" x2="8.254" y2="4.556" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ea8cda"/>
            <stop offset="1" stopColor="#9951e6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kp" x1="8.254" y1="4.575" x2="8.254" y2="4.575" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ea8cda"/>
            <stop offset="1" stopColor="#9851e7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kq" x1="8.254" y1="4.594" x2="8.254" y2="4.594" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ea8cdb"/>
            <stop offset="1" stopColor="#9851e8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kr" x1="8.254" y1="4.613" x2="8.254" y2="4.612" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e98cdb"/>
            <stop offset="1" stopColor="#9752e8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ks" x1="8.254" y1="4.631" x2="8.254" y2="4.631" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e98cdc"/>
            <stop offset="1" stopColor="#9652e9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kt" x1="8.254" y1="4.65" x2="8.254" y2="4.65" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e98cdc"/>
            <stop offset="1" stopColor="#9552e9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ku" x1="8.254" y1="4.669" x2="8.254" y2="4.669" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e98cdd"/>
            <stop offset="1" stopColor="#9452ea"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kv" x1="8.254" y1="4.687" x2="8.254" y2="4.687" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e98cdd"/>
            <stop offset="1" stopColor="#9453eb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kw" x1="8.254" y1="4.706" x2="8.254" y2="4.706" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e98cde"/>
            <stop offset="1" stopColor="#9353eb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kx" x1="8.254" y1="4.725" x2="8.254" y2="4.725" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e88cde"/>
            <stop offset="1" stopColor="#9253ec"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ky" x1="8.254" y1="4.743" x2="8.254" y2="4.743" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e88cdf"/>
            <stop offset="1" stopColor="#9153ed"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-kz" x1="8.254" y1="4.762" x2="8.254" y2="4.762" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e88cdf"/>
            <stop offset="1" stopColor="#9154ed"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-la" x1="8.254" y1="4.781" x2="8.254" y2="4.781" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e88ce0"/>
            <stop offset="1" stopColor="#9054ee"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lb" x1="8.254" y1="4.799" x2="8.254" y2="4.799" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e88ce0"/>
            <stop offset="1" stopColor="#8f54ee"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lc" x1="8.254" y1="4.818" x2="8.254" y2="4.818" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e88ce1"/>
            <stop offset="1" stopColor="#8e54ef"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ld" x1="8.254" y1="4.837" x2="8.254" y2="4.837" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e78ce1"/>
            <stop offset="1" stopColor="#8d55f0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-le" x1="8.254" y1="4.855" x2="8.254" y2="4.855" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e78ce2"/>
            <stop offset="1" stopColor="#8d55f0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lf" x1="8.254" y1="4.874" x2="8.254" y2="4.874" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e78ce2"/>
            <stop offset="1" stopColor="#8c55f1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lg" x1="8.254" y1="4.893" x2="8.254" y2="4.893" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e78ce3"/>
            <stop offset="1" stopColor="#8b55f1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lh" x1="8.254" y1="4.911" x2="8.254" y2="4.911" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e78ce3"/>
            <stop offset="1" stopColor="#8a56f2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-li" x1="8.254" y1="4.93" x2="8.254" y2="4.93" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e68de4"/>
            <stop offset="1" stopColor="#8a56f3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lj" x1="8.254" y1="4.949" x2="8.254" y2="4.949" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e68de4"/>
            <stop offset="1" stopColor="#8956f3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lk" x1="8.254" y1="4.967" x2="8.254" y2="4.967" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e68de5"/>
            <stop offset="1" stopColor="#8856f4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ll" x1="8.254" y1="4.986" x2="8.254" y2="4.986" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e68de5"/>
            <stop offset="1" stopColor="#8757f5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lm" x1="8.253" y1="5.005" x2="8.253" y2="5.005" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e68de6"/>
            <stop offset="1" stopColor="#8657f5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ln" x1="8.251" y1="5.024" x2="8.251" y2="5.024" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e68de6"/>
            <stop offset="1" stopColor="#8657f6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lo" x1="8.248" y1="5.042" x2="8.248" y2="5.042" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e58de7"/>
            <stop offset="1" stopColor="#8557f6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lp" x1="8.244" y1="5.061" x2="8.244" y2="5.06" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e58de7"/>
            <stop offset="1" stopColor="#8458f7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lq" x1="8.238" y1="5.078" x2="8.238" y2="5.078" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e58de8"/>
            <stop offset="1" stopColor="#8358f8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lr" x1="8.232" y1="5.096" x2="8.232" y2="5.096" xlinkHref="#lq"/>
          <linearGradient id="glasshive-logo-dark-ls" x1="8.225" y1="5.113" x2="8.225" y2="5.113" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e58de9"/>
            <stop offset="1" stopColor="#8258f9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lt" x1="8.216" y1="5.129" x2="8.216" y2="5.129" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e58de9"/>
            <stop offset="1" stopColor="#8159f9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lu" x1="8.207" y1="5.145" x2="8.207" y2="5.145" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e48dea"/>
            <stop offset="1" stopColor="#8059fa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lv" x1="8.196" y1="5.16" x2="8.196" y2="5.16" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e48dea"/>
            <stop offset="1" stopColor="#7f59fb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lw" x1="8.185" y1="5.175" x2="8.185" y2="5.175" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e48deb"/>
            <stop offset="1" stopColor="#7f59fb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lx" x1="8.173" y1="5.189" x2="8.173" y2="5.189" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e48deb"/>
            <stop offset="1" stopColor="#7e5afc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ly" x1="8.159" y1="5.203" x2="8.159" y2="5.202" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e48dec"/>
            <stop offset="1" stopColor="#7d5afd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-lz" x1="8.146" y1="5.215" x2="8.146" y2="5.215" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e48dec"/>
            <stop offset="1" stopColor="#7c5afd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ma" x1="8.131" y1="5.227" x2="8.131" y2="5.227" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e38ded"/>
            <stop offset="1" stopColor="#7c5afe"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mb" x1="8.115" y1="5.238" x2="8.115" y2="5.238" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e38ded"/>
            <stop offset="1" stopColor="#7b5bfe"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mc" x1="8.099" y1="5.248" x2="8.099" y2="5.248" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e38dee"/>
            <stop offset="1" stopColor="#7a5bff"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-md" x1="8.083" y1="5.257" x2="8.083" y2="5.257" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e28dee"/>
            <stop offset="1" stopColor="#7a5cff"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-me" x1="8.067" y1="5.267" x2="8.067" y2="5.266" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#e08dee"/>
            <stop offset="1" stopColor="#7a5dfe"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mf" x1="8.051" y1="5.276" x2="8.051" y2="5.276" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#df8eee"/>
            <stop offset="1" stopColor="#7a5efe"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mg" x1="8.036" y1="5.285" x2="8.036" y2="5.285" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#de8eee"/>
            <stop offset="1" stopColor="#7a5ffe"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mh" x1="8.02" y1="5.295" x2="8.02" y2="5.295" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#dd8eef"/>
            <stop offset="1" stopColor="#7a60fe"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mi" x1="8.004" y1="5.304" x2="8.004" y2="5.304" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#db8eef"/>
            <stop offset="1" stopColor="#7a61fd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mj" x1="7.988" y1="5.314" x2="7.988" y2="5.314" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#da8eef"/>
            <stop offset="1" stopColor="#7a61fd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mk" x1="7.973" y1="5.323" x2="7.973" y2="5.323" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d98fef"/>
            <stop offset="1" stopColor="#7a62fd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ml" x1="7.957" y1="5.332" x2="7.957" y2="5.332" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d88fef"/>
            <stop offset="1" stopColor="#7a63fd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mm" x1="7.941" y1="5.342" x2="7.941" y2="5.342" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d68fef"/>
            <stop offset="1" stopColor="#7a64fc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mn" x1="7.925" y1="5.351" x2="7.925" y2="5.351" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d58fef"/>
            <stop offset="1" stopColor="#7a65fc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mo" x1="7.909" y1="5.36" x2="7.909" y2="5.36" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d48fef"/>
            <stop offset="1" stopColor="#7a66fc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mp" x1="7.893" y1="5.37" x2="7.893" y2="5.37" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d390ef"/>
            <stop offset="1" stopColor="#7a67fc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mq" x1="7.878" y1="5.379" x2="7.878" y2="5.379" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d190ef"/>
            <stop offset="1" stopColor="#7b68fb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mr" x1="7.862" y1="5.389" x2="7.862" y2="5.389" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#d090f0"/>
            <stop offset="1" stopColor="#7b69fb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ms" x1="7.846" y1="5.398" x2="7.846" y2="5.398" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#cf90f0"/>
            <stop offset="1" stopColor="#7b6afb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mt" x1="7.83" y1="5.407" x2="7.83" y2="5.407" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ce90f0"/>
            <stop offset="1" stopColor="#7b6bfb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mu" x1="7.814" y1="5.417" x2="7.814" y2="5.417" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#cc91f0"/>
            <stop offset="1" stopColor="#7b6cfa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mv" x1="7.798" y1="5.426" x2="7.798" y2="5.426" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#cb91f0"/>
            <stop offset="1" stopColor="#7b6dfa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mw" x1="7.782" y1="5.435" x2="7.782" y2="5.435" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ca91f0"/>
            <stop offset="1" stopColor="#7b6efa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mx" x1="7.767" y1="5.445" x2="7.767" y2="5.445" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c991f0"/>
            <stop offset="1" stopColor="#7b6efa"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-my" x1="7.751" y1="5.454" x2="7.751" y2="5.454" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c792f0"/>
            <stop offset="1" stopColor="#7b6ff9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-mz" x1="7.735" y1="5.464" x2="7.735" y2="5.464" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c692f0"/>
            <stop offset="1" stopColor="#7b70f9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-na" x1="7.719" y1="5.473" x2="7.719" y2="5.473" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c592f1"/>
            <stop offset="1" stopColor="#7b71f9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nb" x1="7.703" y1="5.482" x2="7.703" y2="5.482" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c392f1"/>
            <stop offset="1" stopColor="#7b72f9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nc" x1="7.687" y1="5.492" x2="7.687" y2="5.492" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c292f1"/>
            <stop offset="1" stopColor="#7b73f8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nd" x1="7.671" y1="5.501" x2="7.671" y2="5.501" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c193f1"/>
            <stop offset="1" stopColor="#7b74f8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ne" x1="7.655" y1="5.51" x2="7.655" y2="5.51" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#c093f1"/>
            <stop offset="1" stopColor="#7b75f8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nf" x1="7.639" y1="5.52" x2="7.639" y2="5.52" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#be93f1"/>
            <stop offset="1" stopColor="#7b76f8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ng" x1="7.623" y1="5.529" x2="7.623" y2="5.529" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#bd93f1"/>
            <stop offset="1" stopColor="#7b77f7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nh" x1="7.608" y1="5.538" x2="7.608" y2="5.538" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#bc93f1"/>
            <stop offset="1" stopColor="#7b78f7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ni" x1="7.592" y1="5.548" x2="7.592" y2="5.548" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#bb94f1"/>
            <stop offset="1" stopColor="#7b79f7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nj" x1="7.576" y1="5.557" x2="7.576" y2="5.557" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b994f1"/>
            <stop offset="1" stopColor="#7b7af7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nk" x1="7.56" y1="5.566" x2="7.56" y2="5.566" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b894f2"/>
            <stop offset="1" stopColor="#7b7af6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nl" x1="7.544" y1="5.576" x2="7.544" y2="5.576" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b794f2"/>
            <stop offset="1" stopColor="#7b7bf6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nm" x1="7.528" y1="5.585" x2="7.528" y2="5.585" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b694f2"/>
            <stop offset="1" stopColor="#7b7cf6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nn" x1="7.512" y1="5.594" x2="7.512" y2="5.594" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b495f2"/>
            <stop offset="1" stopColor="#7b7df6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-no" x1="7.496" y1="5.604" x2="7.496" y2="5.604" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b395f2"/>
            <stop offset="1" stopColor="#7b7ef5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-np" x1="7.48" y1="5.613" x2="7.48" y2="5.613" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b295f2"/>
            <stop offset="1" stopColor="#7b7ff5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nq" x1="7.464" y1="5.622" x2="7.464" y2="5.622" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#b195f2"/>
            <stop offset="1" stopColor="#7b80f5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nr" x1="7.448" y1="5.632" x2="7.448" y2="5.632" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#af95f2"/>
            <stop offset="1" stopColor="#7c81f5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ns" x1="7.432" y1="5.641" x2="7.432" y2="5.641" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ae96f2"/>
            <stop offset="1" stopColor="#7c82f4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nt" x1="7.416" y1="5.65" x2="7.416" y2="5.65" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ad96f3"/>
            <stop offset="1" stopColor="#7c83f4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nu" x1="7.4" y1="5.66" x2="7.4" y2="5.66" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ac96f3"/>
            <stop offset="1" stopColor="#7c84f4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nv" x1="7.384" y1="5.669" x2="7.384" y2="5.669" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#aa96f3"/>
            <stop offset="1" stopColor="#7c85f4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nw" x1="7.368" y1="5.678" x2="7.368" y2="5.678" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a996f3"/>
            <stop offset="1" stopColor="#7c86f3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nx" x1="7.352" y1="5.688" x2="7.352" y2="5.687" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a897f3"/>
            <stop offset="1" stopColor="#7c86f3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ny" x1="7.336" y1="5.697" x2="7.336" y2="5.697" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a697f3"/>
            <stop offset="1" stopColor="#7c87f3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-nz" x1="7.32" y1="5.706" x2="7.32" y2="5.706" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a597f3"/>
            <stop offset="1" stopColor="#7c88f3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oa" x1="7.304" y1="5.715" x2="7.304" y2="5.715" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a497f3"/>
            <stop offset="1" stopColor="#7c89f2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ob" x1="7.288" y1="5.725" x2="7.288" y2="5.725" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a397f3"/>
            <stop offset="1" stopColor="#7c8af2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oc" x1="7.272" y1="5.734" x2="7.272" y2="5.734" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a198f3"/>
            <stop offset="1" stopColor="#7c8bf2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-od" x1="7.256" y1="5.743" x2="7.256" y2="5.743" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a098f4"/>
            <stop offset="1" stopColor="#7c8cf2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oe" x1="7.24" y1="5.753" x2="7.24" y2="5.753" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9f98f4"/>
            <stop offset="1" stopColor="#7c8df1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-of" x1="7.224" y1="5.762" x2="7.224" y2="5.762" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9e98f4"/>
            <stop offset="1" stopColor="#7c8ef1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-og" x1="7.208" y1="5.771" x2="7.208" y2="5.771" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9c98f4"/>
            <stop offset="1" stopColor="#7c8ff1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oh" x1="7.192" y1="5.78" x2="7.192" y2="5.78" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9b99f4"/>
            <stop offset="1" stopColor="#7c90f0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oi" x1="7.176" y1="5.79" x2="7.176" y2="5.79" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9a99f4"/>
            <stop offset="1" stopColor="#7c91f0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oj" x1="7.16" y1="5.799" x2="7.16" y2="5.799" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9999f4"/>
            <stop offset="1" stopColor="#7c92f0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ok" x1="7.144" y1="5.808" x2="7.144" y2="5.808" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#9799f4"/>
            <stop offset="1" stopColor="#7c93f0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ol" x1="7.128" y1="5.818" x2="7.128" y2="5.817" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#969af4"/>
            <stop offset="1" stopColor="#7c93ef"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-om" x1="7.112" y1="5.827" x2="7.112" y2="5.827" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#959af5"/>
            <stop offset="1" stopColor="#7c94ef"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-on" x1="7.096" y1="5.836" x2="7.096" y2="5.836" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#949af5"/>
            <stop offset="1" stopColor="#7c95ef"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oo" x1="7.08" y1="5.845" x2="7.08" y2="5.845" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#929af5"/>
            <stop offset="1" stopColor="#7c96ef"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-op" x1="7.064" y1="5.855" x2="7.064" y2="5.855" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#919af5"/>
            <stop offset="1" stopColor="#7c97ee"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oq" x1="7.048" y1="5.864" x2="7.048" y2="5.864" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#909bf5"/>
            <stop offset="1" stopColor="#7c98ee"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-or" x1="7.032" y1="5.873" x2="7.032" y2="5.873" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#8f9bf5"/>
            <stop offset="1" stopColor="#7c99ee"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-os" x1="7.015" y1="5.882" x2="7.015" y2="5.882" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#8d9bf5"/>
            <stop offset="1" stopColor="#7d9aee"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ot" x1="6.999" y1="5.891" x2="6.999" y2="5.891" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#8c9bf5"/>
            <stop offset="1" stopColor="#7d9bed"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ou" x1="6.982" y1="5.899" x2="6.982" y2="5.899" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#8b9bf5"/>
            <stop offset="1" stopColor="#7d9ced"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ov" x1="6.964" y1="5.906" x2="6.964" y2="5.906" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#899cf5"/>
            <stop offset="1" stopColor="#7d9ded"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ow" x1="6.947" y1="5.913" x2="6.947" y2="5.913" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#889cf6"/>
            <stop offset="1" stopColor="#7d9eed"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ox" x1="6.929" y1="5.918" x2="6.929" y2="5.918" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#879cf6"/>
            <stop offset="1" stopColor="#7d9fec"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oy" x1="6.911" y1="5.923" x2="6.911" y2="5.923" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#869cf6"/>
            <stop offset="1" stopColor="#7d9fec"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-oz" x1="6.893" y1="5.927" x2="6.893" y2="5.927" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#849cf6"/>
            <stop offset="1" stopColor="#7da0ec"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pa" x1="6.875" y1="5.931" x2="6.875" y2="5.931" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#839df6"/>
            <stop offset="1" stopColor="#7da1ec"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pb" x1="6.857" y1="5.933" x2="6.857" y2="5.933" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#829df6"/>
            <stop offset="1" stopColor="#7da2eb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pc" x1="6.839" y1="5.935" x2="6.839" y2="5.935" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#819df6"/>
            <stop offset="1" stopColor="#7da3eb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pd" x1="6.82" y1="5.936" x2="6.82" y2="5.936" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7f9df6"/>
            <stop offset="1" stopColor="#7da4eb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pe" x1="6.802" y1="5.937" x2="6.802" y2="5.937" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7e9df6"/>
            <stop offset="1" stopColor="#7da5eb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pf" x1="6.784" y1="5.936" x2="6.784" y2="5.936" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7d9ef7"/>
            <stop offset="1" stopColor="#7da6ea"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pg" x1="6.765" y1="5.935" x2="6.765" y2="5.935" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7c9ef7"/>
            <stop offset="1" stopColor="#7da7ea"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ph" x1="6.747" y1="5.933" x2="6.747" y2="5.933" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7a9ef7"/>
            <stop offset="1" stopColor="#7da8ea"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pi" x1="6.728" y1="5.931" x2="6.728" y2="5.931" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#799ef7"/>
            <stop offset="1" stopColor="#7da9ea"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pj" x1="6.71" y1="5.927" x2="6.71" y2="5.927" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#789ef7"/>
            <stop offset="1" stopColor="#7daae9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pk" x1="6.692" y1="5.923" x2="6.692" y2="5.923" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#779ff7"/>
            <stop offset="1" stopColor="#7dabe9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pl" x1="6.674" y1="5.918" x2="6.674" y2="5.918" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#759ff7"/>
            <stop offset="1" stopColor="#7dace9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pm" x1="6.657" y1="5.912" x2="6.657" y2="5.912" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#749ff7"/>
            <stop offset="1" stopColor="#7dace9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pn" x1="6.639" y1="5.906" x2="6.639" y2="5.906" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#739ff7"/>
            <stop offset="1" stopColor="#7dade8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-po" x1="6.622" y1="5.899" x2="6.622" y2="5.899" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#729ff8"/>
            <stop offset="1" stopColor="#7daee8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pp" x1="6.605" y1="5.891" x2="6.605" y2="5.891" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#70a0f8"/>
            <stop offset="1" stopColor="#7dafe8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pq" x1="6.588" y1="5.882" x2="6.588" y2="5.882" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#6fa0f8"/>
            <stop offset="1" stopColor="#7db0e8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pr" x1="6.572" y1="5.873" x2="6.572" y2="5.873" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#6ea0f8"/>
            <stop offset="1" stopColor="#7db1e7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ps" x1="6.555" y1="5.864" x2="6.555" y2="5.864" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#6ca0f8"/>
            <stop offset="1" stopColor="#7eb2e7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pt" x1="6.539" y1="5.855" x2="6.539" y2="5.855" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#6ba0f8"/>
            <stop offset="1" stopColor="#7eb3e7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pu" x1="6.523" y1="5.846" x2="6.523" y2="5.846" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#6aa1f8"/>
            <stop offset="1" stopColor="#7eb4e7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pv" x1="6.506" y1="5.837" x2="6.506" y2="5.837" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#69a1f8"/>
            <stop offset="1" stopColor="#7eb5e6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pw" x1="6.49" y1="5.828" x2="6.49" y2="5.828" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#67a1f8"/>
            <stop offset="1" stopColor="#7eb6e6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-px" x1="6.474" y1="5.819" x2="6.474" y2="5.819" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#66a1f8"/>
            <stop offset="1" stopColor="#7eb7e6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-py" x1="6.457" y1="5.81" x2="6.457" y2="5.81" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#65a1f9"/>
            <stop offset="1" stopColor="#7eb8e6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-pz" x1="6.441" y1="5.801" x2="6.441" y2="5.801" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#64a2f9"/>
            <stop offset="1" stopColor="#7eb8e5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qa" x1="6.425" y1="5.792" x2="6.425" y2="5.792" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#62a2f9"/>
            <stop offset="1" stopColor="#7eb9e5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qb" x1="6.408" y1="5.783" x2="6.408" y2="5.783" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#61a2f9"/>
            <stop offset="1" stopColor="#7ebae5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qc" x1="6.392" y1="5.774" x2="6.392" y2="5.774" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#60a2f9"/>
            <stop offset="1" stopColor="#7ebbe5"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qd" x1="6.376" y1="5.765" x2="6.376" y2="5.765" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#5fa3f9"/>
            <stop offset="1" stopColor="#7ebce4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qe" x1="6.359" y1="5.756" x2="6.359" y2="5.756" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#5da3f9"/>
            <stop offset="1" stopColor="#7ebde4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qf" x1="6.343" y1="5.747" x2="6.343" y2="5.747" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#5ca3f9"/>
            <stop offset="1" stopColor="#7ebee4"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qg" x1="6.327" y1="5.738" x2="6.327" y2="5.738" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#5ba3f9"/>
            <stop offset="1" stopColor="#7ebfe3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qh" x1="6.31" y1="5.729" x2="6.31" y2="5.729" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#5aa3fa"/>
            <stop offset="1" stopColor="#7ec0e3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qi" x1="6.294" y1="5.72" x2="6.294" y2="5.72" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#58a4fa"/>
            <stop offset="1" stopColor="#7ec1e3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qj" x1="6.278" y1="5.711" x2="6.278" y2="5.711" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#57a4fa"/>
            <stop offset="1" stopColor="#7ec2e3"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qk" x1="6.261" y1="5.702" x2="6.261" y2="5.702" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#56a4fa"/>
            <stop offset="1" stopColor="#7ec3e2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ql" x1="6.245" y1="5.693" x2="6.245" y2="5.692" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#55a4fa"/>
            <stop offset="1" stopColor="#7ec4e2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qm" x1="6.228" y1="5.683" x2="6.228" y2="5.683" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#53a4fa"/>
            <stop offset="1" stopColor="#7ec5e2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qn" x1="6.212" y1="5.675" x2="6.212" y2="5.674" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#52a5fa"/>
            <stop offset="1" stopColor="#7ec5e2"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qo" x1="6.196" y1="5.665" x2="6.196" y2="5.665" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#51a5fa"/>
            <stop offset="1" stopColor="#7ec6e1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qp" x1="6.179" y1="5.656" x2="6.179" y2="5.656" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#4fa5fa"/>
            <stop offset="1" stopColor="#7ec7e1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qq" x1="6.163" y1="5.647" x2="6.163" y2="5.647" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#4ea5fa"/>
            <stop offset="1" stopColor="#7ec8e1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qr" x1="6.147" y1="5.638" x2="6.147" y2="5.638" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#4da5fb"/>
            <stop offset="1" stopColor="#7ec9e1"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qs" x1="6.13" y1="5.629" x2="6.13" y2="5.629" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#4ca6fb"/>
            <stop offset="1" stopColor="#7ecae0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qt" x1="6.114" y1="5.62" x2="6.114" y2="5.62" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#4aa6fb"/>
            <stop offset="1" stopColor="#7fcbe0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qu" x1="6.098" y1="5.611" x2="6.098" y2="5.611" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#49a6fb"/>
            <stop offset="1" stopColor="#7fcce0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qv" x1="6.081" y1="5.602" x2="6.081" y2="5.602" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#48a6fb"/>
            <stop offset="1" stopColor="#7fcde0"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qw" x1="6.065" y1="5.593" x2="6.065" y2="5.593" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#47a6fb"/>
            <stop offset="1" stopColor="#7fcedf"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qx" x1="6.048" y1="5.584" x2="6.048" y2="5.584" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#45a7fb"/>
            <stop offset="1" stopColor="#7fcfdf"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qy" x1="6.032" y1="5.575" x2="6.032" y2="5.575" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#44a7fb"/>
            <stop offset="1" stopColor="#7fd0df"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-qz" x1="6.016" y1="5.566" x2="6.016" y2="5.566" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#43a7fb"/>
            <stop offset="1" stopColor="#7fd1df"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ra" x1="5.999" y1="5.557" x2="5.999" y2="5.557" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#42a7fc"/>
            <stop offset="1" stopColor="#7fd1de"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rb" x1="5.983" y1="5.548" x2="5.983" y2="5.548" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#40a7fc"/>
            <stop offset="1" stopColor="#7fd2de"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rc" x1="5.966" y1="5.538" x2="5.966" y2="5.538" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3fa8fc"/>
            <stop offset="1" stopColor="#7fd3de"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rd" x1="5.95" y1="5.529" x2="5.95" y2="5.529" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3ea8fc"/>
            <stop offset="1" stopColor="#7fd4de"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-re" x1="5.934" y1="5.52" x2="5.934" y2="5.52" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3da8fc"/>
            <stop offset="1" stopColor="#7fd5dd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rf" x1="5.917" y1="5.511" x2="5.917" y2="5.511" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3ba8fc"/>
            <stop offset="1" stopColor="#7fd6dd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rg" x1="5.901" y1="5.502" x2="5.901" y2="5.502" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3aa8fc"/>
            <stop offset="1" stopColor="#7fd7dd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rh" x1="5.885" y1="5.493" x2="5.885" y2="5.493" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#39a9fc"/>
            <stop offset="1" stopColor="#7fd8dd"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ri" x1="5.868" y1="5.484" x2="5.868" y2="5.484" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#38a9fc"/>
            <stop offset="1" stopColor="#7fd9dc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rj" x1="5.852" y1="5.475" x2="5.852" y2="5.475" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#36a9fc"/>
            <stop offset="1" stopColor="#7fdadc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rk" x1="5.835" y1="5.466" x2="5.835" y2="5.466" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#35a9fd"/>
            <stop offset="1" stopColor="#7fdbdc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rl" x1="5.819" y1="5.457" x2="5.819" y2="5.457" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#34a9fd"/>
            <stop offset="1" stopColor="#7fdcdc"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rm" x1="5.803" y1="5.448" x2="5.803" y2="5.447" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#32aafd"/>
            <stop offset="1" stopColor="#7fdddb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rn" x1="5.786" y1="5.438" x2="5.786" y2="5.438" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#31aafd"/>
            <stop offset="1" stopColor="#7fdddb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ro" x1="5.77" y1="5.429" x2="5.77" y2="5.429" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#30aafd"/>
            <stop offset="1" stopColor="#7fdedb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rp" x1="5.753" y1="5.42" x2="5.753" y2="5.42" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2faafd"/>
            <stop offset="1" stopColor="#7fdfdb"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rq" x1="5.737" y1="5.411" x2="5.737" y2="5.411" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2dabfd"/>
            <stop offset="1" stopColor="#7fe0da"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rr" x1="5.72" y1="5.402" x2="5.72" y2="5.402" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2cabfd"/>
            <stop offset="1" stopColor="#7fe1da"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rs" x1="5.704" y1="5.393" x2="5.704" y2="5.393" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2babfd"/>
            <stop offset="1" stopColor="#7fe2da"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rt" x1="5.688" y1="5.384" x2="5.688" y2="5.384" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2aabfe"/>
            <stop offset="1" stopColor="#7fe3da"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ru" x1="5.671" y1="5.375" x2="5.671" y2="5.375" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#28abfe"/>
            <stop offset="1" stopColor="#80e4d9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rv" x1="5.655" y1="5.365" x2="5.655" y2="5.365" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#27acfe"/>
            <stop offset="1" stopColor="#80e5d9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rw" x1="5.638" y1="5.356" x2="5.638" y2="5.356" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#26acfe"/>
            <stop offset="1" stopColor="#80e6d9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rx" x1="5.622" y1="5.347" x2="5.622" y2="5.347" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#25acfe"/>
            <stop offset="1" stopColor="#80e7d9"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-ry" x1="5.606" y1="5.338" x2="5.606" y2="5.338" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#23acfe"/>
            <stop offset="1" stopColor="#80e8d8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-rz" x1="5.589" y1="5.329" x2="5.589" y2="5.329" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#22acfe"/>
            <stop offset="1" stopColor="#80e9d8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sa" x1="5.573" y1="5.32" x2="5.573" y2="5.32" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#21adfe"/>
            <stop offset="1" stopColor="#80ead8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sb" x1="5.556" y1="5.311" x2="5.556" y2="5.311" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#20adfe"/>
            <stop offset="1" stopColor="#80ead8"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sc" x1="5.54" y1="5.302" x2="5.54" y2="5.302" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#1eadfe"/>
            <stop offset="1" stopColor="#80ebd7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sd" x1="5.523" y1="5.292" x2="5.523" y2="5.292" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#1dadff"/>
            <stop offset="1" stopColor="#80ecd7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-se" x1="5.507" y1="5.283" x2="5.507" y2="5.283" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#1cadff"/>
            <stop offset="1" stopColor="#80edd7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sf" x1="5.49" y1="5.274" x2="5.49" y2="5.274" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#1baeff"/>
            <stop offset="1" stopColor="#80eed7"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sg" x1="5.474" y1="5.265" x2="5.474" y2="5.265" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#19aeff"/>
            <stop offset="1" stopColor="#80efd6"/>
          </linearGradient>
          <linearGradient id="glasshive-logo-dark-sh" x1="5.458" y1="5.256" x2="5.458" y2="5.256" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#18aeff"/>
            <stop offset="1" stopColor="#80f0d6"/>
          </linearGradient>
          <filter id="glasshive-logo-dark-si" x="0" y="0" width="80.08" height="85.308" filterUnits="userSpaceOnUse">
            <feOffset dx="2" dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="sj"/>
            <feFlood floodColor="#fff"/>
            <feComposite operator="in" in2="sj"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g transform="translate(-61.664 -35.587)">
          <g className="glasshive-logo-dark-a" transform="translate(148.393 56.326)">
            <g className="glasshive-logo-dark-sc" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M838.817,225.232c0,10.079-7.511,17.4-17.881,17.4-10.079,0-17.93-8.092-17.93-18.414a18.409,18.409,0,0,1,31.013-13.375,2.97,2.97,0,0,1,.1,4.167,2.913,2.913,0,0,1-4.119.145,12.342,12.342,0,0,0-8.577-3.441,12.5,12.5,0,0,0-12.5,12.5c0,7.026,5.282,12.551,12.018,12.551,6.251,0,10.855-3.78,11.824-9.352h-6.348a2.932,2.932,0,1,1,0-5.863h9.4a2.927,2.927,0,0,1,2.956,2.908v.242A1.812,1.812,0,0,1,838.817,225.232Z" transform="translate(-716.28 -184)"/>
            </g>
            <g className="glasshive-logo-dark-sb" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M1159.25,242.629a2.927,2.927,0,0,1-2.907-2.956V208.8a2.932,2.932,0,1,1,5.863,0v30.868A2.937,2.937,0,0,1,1159.25,242.629Z" transform="translate(-1030.02 -184)"/>
            </g>
            <g className="glasshive-logo-dark-sa" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M1264.763,321.056v18.56a2.772,2.772,0,0,1-2.811,2.762,2.573,2.573,0,0,1-2.568-2.762v-.582a11.056,11.056,0,0,1-7.414,2.859,11.7,11.7,0,1,1,0-23.405,11.509,11.509,0,0,1,7.414,2.811v-.242a2.573,2.573,0,0,1,2.568-2.762A2.772,2.772,0,0,1,1264.763,321.056Zm-6.736,9.159a6.082,6.082,0,1,0-6.057,6.009A6.091,6.091,0,0,0,1258.027,330.215Z" transform="translate(-1104.52 -283.84)"/>
            </g>
            <g className="glasshive-logo-dark-rz" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M1497.119,344.772a12.19,12.19,0,0,1-7.8-2.762,2.9,2.9,0,0,1,3.925-4.264c.872.824,4.119,1.938,5.96.727a1.606,1.606,0,0,0,.775-1.357,1.585,1.585,0,0,0-1.066-1.357,4.989,4.989,0,0,0-2.083-.242,11.046,11.046,0,0,1-3.828-.533,7.27,7.27,0,0,1-4.749-5.96,7.171,7.171,0,0,1,2.181-6.009c2.423-2.229,5.96-2.568,8.529-2.132a8.672,8.672,0,0,1,4.6,2.132,2.9,2.9,0,0,1,.194,4.071,2.955,2.955,0,0,1-2.471.921,3.01,3.01,0,0,1-1.26-.436,4.422,4.422,0,0,0-2.035-.969c-1.454-.242-4.167-.145-3.974,1.938a1.916,1.916,0,0,0,.872,1.018,7.9,7.9,0,0,0,1.987.145,11.24,11.24,0,0,1,4.458.775,7.4,7.4,0,0,1,4.41,6.639,7.511,7.511,0,0,1-3.344,6.2A9.47,9.47,0,0,1,1497.119,344.772Z" transform="translate(-1324.72 -285.99)"/>
            </g>
            <g className="glasshive-logo-dark-ry" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M1672.269,344.772a12.191,12.191,0,0,1-7.8-2.762,2.9,2.9,0,0,1,3.925-4.264c.872.824,4.119,1.938,5.96.727a1.606,1.606,0,0,0,.775-1.357,1.584,1.584,0,0,0-1.066-1.357,4.991,4.991,0,0,0-2.084-.242,11.044,11.044,0,0,1-3.828-.533,7.27,7.27,0,0,1-4.749-5.96,7.17,7.17,0,0,1,2.181-6.009c2.423-2.229,5.96-2.568,8.529-2.132a8.671,8.671,0,0,1,4.6,2.132,2.9,2.9,0,0,1,.194,4.071,2.955,2.955,0,0,1-2.471.921,3.012,3.012,0,0,1-1.26-.436,4.421,4.421,0,0,0-2.035-.969c-1.454-.242-4.167-.145-3.974,1.938a1.916,1.916,0,0,0,.872,1.018,7.905,7.905,0,0,0,1.987.145,11.24,11.24,0,0,1,4.458.775,7.4,7.4,0,0,1,4.41,6.639,7.511,7.511,0,0,1-3.344,6.2A9.47,9.47,0,0,1,1672.269,344.772Z" transform="translate(-1480.24 -285.99)"/>
            </g>
            <g className="glasshive-logo-dark-rx" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M1872.381,198.858v30.868a2.968,2.968,0,0,1-2.956,2.956,2.937,2.937,0,0,1-2.956-2.956v-12.26h-17.881v12.26a2.968,2.968,0,0,1-2.956,2.956,2.937,2.937,0,0,1-2.956-2.956V198.858a2.937,2.937,0,0,1,2.956-2.956,2.968,2.968,0,0,1,2.956,2.956V211.6h17.881V198.858a2.937,2.937,0,0,1,2.956-2.956A2.968,2.968,0,0,1,1872.381,198.858Z" transform="translate(-1639.45 -175.16)"/>
            </g>
            <g className="glasshive-logo-dark-rw" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M2142.408,231.514a3.021,3.021,0,0,1-3.053-2.956v-.242a3,3,0,1,1,6.009,0v.242A3,3,0,0,1,2142.408,231.514Zm0,28.445a3.03,3.03,0,0,1-3.053-3v-18.7a3,3,0,0,1,6.009,0v18.7A3.01,3.01,0,0,1,2142.408,259.959Z" transform="translate(-1902.89 -201.28)"/>
            </g>
            <g className="glasshive-logo-dark-rv" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M2225.7,343.955h-.049a2.839,2.839,0,0,1-2.52-1.6l-9.11-18.172a2.9,2.9,0,0,1,1.308-3.877,2.858,2.858,0,0,1,3.828,1.308L2225.7,334.7l6.542-12.745a2.85,2.85,0,0,1,5.088,2.568l-9.11,17.833A2.839,2.839,0,0,1,2225.7,343.955Z" transform="translate(-1968.94 -285.37)"/>
            </g>
            <g className="glasshive-logo-dark-ru" transform="matrix(1, 0, 0, 1, -86.73, -20.74)">
              <path className="glasshive-logo-dark-b" d="M2453.461,332.391h-14.925a6.386,6.386,0,0,0,6.154,4.894,8.112,8.112,0,0,0,5.088-1.841,2.91,2.91,0,0,1,4.119.048,2.868,2.868,0,0,1-.048,4.119,13.5,13.5,0,0,1-9.158,3.441,12.163,12.163,0,0,1,0-24.326,10.574,10.574,0,0,1,2.132.194,11.731,11.731,0,0,1,7.85,5.573,9.367,9.367,0,0,1,1.551,4.749,4.085,4.085,0,0,1-.388,1.405A2.633,2.633,0,0,1,2453.461,332.391Zm-14.1-4.894h10.273a5.391,5.391,0,0,0-4.942-2.908A6.285,6.285,0,0,0,2439.359,327.5Z" transform="translate(-2163.26 -284.23)"/>
            </g>
          </g>
          <g transform="translate(147.393 55.326)">
            <path className="glasshive-logo-dark-b" d="M838.817,225.232c0,10.079-7.511,17.4-17.881,17.4-10.079,0-17.93-8.092-17.93-18.414a18.409,18.409,0,0,1,31.013-13.375,2.97,2.97,0,0,1,.1,4.167,2.913,2.913,0,0,1-4.119.145,12.342,12.342,0,0,0-8.577-3.441,12.5,12.5,0,0,0-12.5,12.5c0,7.026,5.282,12.551,12.018,12.551,6.251,0,10.855-3.78,11.824-9.352h-6.348a2.932,2.932,0,1,1,0-5.863h9.4a2.927,2.927,0,0,1,2.956,2.908v.242A1.812,1.812,0,0,1,838.817,225.232Z" transform="translate(-803.006 -204.734)"/>
            <path className="glasshive-logo-dark-b" d="M1159.25,242.629a2.927,2.927,0,0,1-2.907-2.956V208.8a2.932,2.932,0,1,1,5.863,0v30.868A2.937,2.937,0,0,1,1159.25,242.629Z" transform="translate(-1116.752 -204.734)"/>
            <path className="glasshive-logo-dark-b" d="M1264.763,321.056v18.56a2.772,2.772,0,0,1-2.811,2.762,2.573,2.573,0,0,1-2.568-2.762v-.582a11.056,11.056,0,0,1-7.414,2.859,11.7,11.7,0,1,1,0-23.405,11.509,11.509,0,0,1,7.414,2.811v-.242a2.573,2.573,0,0,1,2.568-2.762A2.772,2.772,0,0,1,1264.763,321.056Zm-6.736,9.159a6.082,6.082,0,1,0-6.057,6.009A6.091,6.091,0,0,0,1258.027,330.215Z" transform="translate(-1191.252 -304.58)"/>
            <path className="glasshive-logo-dark-b" d="M1497.119,344.772a12.19,12.19,0,0,1-7.8-2.762,2.9,2.9,0,0,1,3.925-4.264c.872.824,4.119,1.938,5.96.727a1.606,1.606,0,0,0,.775-1.357,1.585,1.585,0,0,0-1.066-1.357,4.989,4.989,0,0,0-2.083-.242,11.046,11.046,0,0,1-3.828-.533,7.27,7.27,0,0,1-4.749-5.96,7.171,7.171,0,0,1,2.181-6.009c2.423-2.229,5.96-2.568,8.529-2.132a8.672,8.672,0,0,1,4.6,2.132,2.9,2.9,0,0,1,.194,4.071,2.955,2.955,0,0,1-2.471.921,3.01,3.01,0,0,1-1.26-.436,4.422,4.422,0,0,0-2.035-.969c-1.454-.242-4.167-.145-3.974,1.938a1.916,1.916,0,0,0,.872,1.018,7.9,7.9,0,0,0,1.987.145,11.24,11.24,0,0,1,4.458.775,7.4,7.4,0,0,1,4.41,6.639,7.511,7.511,0,0,1-3.344,6.2A9.47,9.47,0,0,1,1497.119,344.772Z" transform="translate(-1411.445 -306.732)"/>
            <path className="glasshive-logo-dark-b" d="M1672.269,344.772a12.191,12.191,0,0,1-7.8-2.762,2.9,2.9,0,0,1,3.925-4.264c.872.824,4.119,1.938,5.96.727a1.606,1.606,0,0,0,.775-1.357,1.584,1.584,0,0,0-1.066-1.357,4.991,4.991,0,0,0-2.084-.242,11.044,11.044,0,0,1-3.828-.533,7.27,7.27,0,0,1-4.749-5.96,7.17,7.17,0,0,1,2.181-6.009c2.423-2.229,5.96-2.568,8.529-2.132a8.671,8.671,0,0,1,4.6,2.132,2.9,2.9,0,0,1,.194,4.071,2.955,2.955,0,0,1-2.471.921,3.012,3.012,0,0,1-1.26-.436,4.421,4.421,0,0,0-2.035-.969c-1.454-.242-4.167-.145-3.974,1.938a1.916,1.916,0,0,0,.872,1.018,7.905,7.905,0,0,0,1.987.145,11.24,11.24,0,0,1,4.458.775,7.4,7.4,0,0,1,4.41,6.639,7.511,7.511,0,0,1-3.344,6.2A9.47,9.47,0,0,1,1672.269,344.772Z" transform="translate(-1566.97 -306.732)"/>
            <path className="glasshive-logo-dark-b" d="M1872.381,198.858v30.868a2.968,2.968,0,0,1-2.956,2.956,2.937,2.937,0,0,1-2.956-2.956v-12.26h-17.881v12.26a2.968,2.968,0,0,1-2.956,2.956,2.937,2.937,0,0,1-2.956-2.956V198.858a2.937,2.937,0,0,1,2.956-2.956,2.968,2.968,0,0,1,2.956,2.956V211.6h17.881V198.858a2.937,2.937,0,0,1,2.956-2.956A2.968,2.968,0,0,1,1872.381,198.858Z" transform="translate(-1726.184 -195.902)"/>
            <path className="glasshive-logo-dark-b" d="M2142.408,231.514a3.021,3.021,0,0,1-3.053-2.956v-.242a3,3,0,1,1,6.009,0v.242A3,3,0,0,1,2142.408,231.514Zm0,28.445a3.03,3.03,0,0,1-3.053-3v-18.7a3,3,0,0,1,6.009,0v18.7A3.01,3.01,0,0,1,2142.408,259.959Z" transform="translate(-1989.62 -222.016)"/>
            <path className="glasshive-logo-dark-b" d="M2225.7,343.955h-.049a2.839,2.839,0,0,1-2.52-1.6l-9.11-18.172a2.9,2.9,0,0,1,1.308-3.877,2.858,2.858,0,0,1,3.828,1.308L2225.7,334.7l6.542-12.745a2.85,2.85,0,0,1,5.088,2.568l-9.11,17.833A2.839,2.839,0,0,1,2225.7,343.955Z" transform="translate(-2055.665 -306.109)"/>
            <path className="glasshive-logo-dark-b" d="M2453.461,332.391h-14.925a6.386,6.386,0,0,0,6.154,4.894,8.112,8.112,0,0,0,5.088-1.841,2.91,2.91,0,0,1,4.119.048,2.868,2.868,0,0,1-.048,4.119,13.5,13.5,0,0,1-9.158,3.441,12.163,12.163,0,0,1,0-24.326,10.574,10.574,0,0,1,2.132.194,11.731,11.731,0,0,1,7.85,5.573,9.367,9.367,0,0,1,1.551,4.749,4.085,4.085,0,0,1-.388,1.405A2.633,2.633,0,0,1,2453.461,332.391Zm-14.1-4.894h10.273a5.391,5.391,0,0,0-4.942-2.908A6.285,6.285,0,0,0,2439.359,327.5Z" transform="translate(-2249.986 -304.964)"/>
          </g>
          <g transform="translate(64.664 37.588)">
            <path className="glasshive-logo-dark-b" d="M98.7,110.9a17.089,17.089,0,0,1-8.546-2.283L73.21,98.829a17.14,17.14,0,0,1-8.546-14.8V64.458a17.141,17.141,0,0,1,8.546-14.8L90.157,39.87a17.141,17.141,0,0,1,17.093,0L124.2,49.655a17.141,17.141,0,0,1,8.546,14.8V84.026a17.14,17.14,0,0,1-8.546,14.8l-16.947,9.784A17.089,17.089,0,0,1,98.7,110.9Zm0-69.489a13.269,13.269,0,0,0-6.636,1.772L75.121,52.963a13.31,13.31,0,0,0-6.636,11.494V84.026A13.31,13.31,0,0,0,75.121,95.52L92.068,105.3a13.311,13.311,0,0,0,13.272,0l16.947-9.784a13.31,13.31,0,0,0,6.636-11.494V64.458a13.31,13.31,0,0,0-6.636-11.494L105.34,43.179A13.27,13.27,0,0,0,98.7,41.406Z" transform="translate(-64.664 -37.588)"/>
            <g transform="translate(11.089 11.869)">
              <circle className="glasshive-logo-dark-c" cx="5.847" cy="5.847" r="5.847" transform="translate(26.792 22.02)"/>
              <circle className="glasshive-logo-dark-d" cx="5.847" cy="5.847" r="5.847" transform="translate(26.989 22.121)"/>
              <circle className="glasshive-logo-dark-e" cx="5.847" cy="5.847" r="5.847" transform="translate(27.186 22.221)"/>
              <circle className="glasshive-logo-dark-f" cx="5.847" cy="5.847" r="5.847" transform="translate(27.383 22.321)"/>
              <circle className="glasshive-logo-dark-g" cx="5.847" cy="5.847" r="5.847" transform="translate(27.58 22.421)"/>
              <circle className="glasshive-logo-dark-h" cx="5.847" cy="5.847" r="5.847" transform="translate(27.777 22.522)"/>
              <circle className="glasshive-logo-dark-i" cx="5.847" cy="5.847" r="5.847" transform="translate(27.974 22.622)"/>
              <circle className="glasshive-logo-dark-j" cx="5.847" cy="5.847" r="5.847" transform="translate(28.171 22.722)"/>
              <circle className="glasshive-logo-dark-k" cx="5.847" cy="5.847" r="5.847" transform="translate(28.368 22.822)"/>
              <circle className="glasshive-logo-dark-l" cx="5.847" cy="5.847" r="5.847" transform="translate(28.565 22.923)"/>
              <circle className="glasshive-logo-dark-m" cx="5.847" cy="5.847" r="5.847" transform="translate(28.762 23.023)"/>
              <circle className="glasshive-logo-dark-n" cx="5.847" cy="5.847" r="5.847" transform="translate(28.959 23.123)"/>
              <circle className="glasshive-logo-dark-o" cx="5.847" cy="5.847" r="5.847" transform="translate(29.156 23.223)"/>
              <circle className="glasshive-logo-dark-p" cx="5.847" cy="5.847" r="5.847" transform="translate(29.353 23.323)"/>
              <circle className="glasshive-logo-dark-q" cx="5.847" cy="5.847" r="5.847" transform="translate(29.55 23.424)"/>
              <circle className="glasshive-logo-dark-r" cx="5.847" cy="5.847" r="5.847" transform="translate(29.747 23.524)"/>
              <circle className="glasshive-logo-dark-s" cx="5.847" cy="5.847" r="5.847" transform="translate(29.944 23.624)"/>
              <circle className="glasshive-logo-dark-t" cx="5.847" cy="5.847" r="5.847" transform="translate(30.141 23.724)"/>
              <circle className="glasshive-logo-dark-u" cx="5.847" cy="5.847" r="5.847" transform="translate(30.338 23.825)"/>
              <circle className="glasshive-logo-dark-v" cx="5.847" cy="5.847" r="5.847" transform="translate(30.535 23.925)"/>
              <circle className="glasshive-logo-dark-w" cx="5.847" cy="5.847" r="5.847" transform="translate(30.732 24.025)"/>
              <circle className="glasshive-logo-dark-x" cx="5.847" cy="5.847" r="5.847" transform="translate(30.929 24.125)"/>
              <circle className="glasshive-logo-dark-y" cx="5.847" cy="5.847" r="5.847" transform="translate(31.126 24.226)"/>
              <circle className="glasshive-logo-dark-z" cx="5.847" cy="5.847" r="5.847" transform="translate(31.323 24.326)"/>
              <circle className="glasshive-logo-dark-aa" cx="5.847" cy="5.847" r="5.847" transform="translate(31.52 24.426)"/>
              <circle className="glasshive-logo-dark-ab" cx="5.847" cy="5.847" r="5.847" transform="translate(31.717 24.527)"/>
              <circle className="glasshive-logo-dark-ac" cx="5.847" cy="5.847" r="5.847" transform="translate(31.914 24.627)"/>
              <circle className="glasshive-logo-dark-ad" cx="5.847" cy="5.847" r="5.847" transform="translate(32.111 24.727)"/>
              <circle className="glasshive-logo-dark-ae" cx="5.847" cy="5.847" r="5.847" transform="translate(32.33 24.82)"/>
              <circle className="glasshive-logo-dark-af" cx="5.847" cy="5.847" r="5.847" transform="translate(32.546 24.923)"/>
              <circle className="glasshive-logo-dark-ag" cx="5.847" cy="5.847" r="5.847" transform="translate(32.749 25.039)"/>
              <circle className="glasshive-logo-dark-ah" cx="5.847" cy="5.847" r="5.847" transform="translate(32.939 25.167)"/>
              <circle className="glasshive-logo-dark-ai" cx="5.847" cy="5.847" r="5.847" transform="translate(33.116 25.307)"/>
              <circle className="glasshive-logo-dark-aj" cx="5.847" cy="5.847" r="5.847" transform="translate(33.28 25.458)"/>
              <circle className="glasshive-logo-dark-ak" cx="5.847" cy="5.847" r="5.847" transform="translate(33.43 25.619)"/>
              <circle className="glasshive-logo-dark-al" cx="5.847" cy="5.847" r="5.847" transform="translate(33.567 25.789)"/>
              <circle className="glasshive-logo-dark-am" cx="5.847" cy="5.847" r="5.847" transform="translate(33.69 25.967)"/>
              <circle className="glasshive-logo-dark-an" cx="5.847" cy="5.847" r="5.847" transform="translate(33.8 26.153)"/>
              <circle className="glasshive-logo-dark-ao" cx="5.847" cy="5.847" r="5.847" transform="translate(33.896 26.344)"/>
              <circle className="glasshive-logo-dark-ap" cx="5.847" cy="5.847" r="5.847" transform="translate(33.978 26.542)"/>
              <circle className="glasshive-logo-dark-aq" cx="5.847" cy="5.847" r="5.847" transform="translate(34.047 26.744)"/>
              <circle className="glasshive-logo-dark-ar" cx="5.847" cy="5.847" r="5.847" transform="translate(34.101 26.95)"/>
              <circle className="glasshive-logo-dark-as" cx="5.847" cy="5.847" r="5.847" transform="translate(34.141 27.158)"/>
              <circle className="glasshive-logo-dark-at" cx="5.847" cy="5.847" r="5.847" transform="translate(34.167 27.369)"/>
              <circle className="glasshive-logo-dark-au" cx="5.847" cy="5.847" r="5.847" transform="translate(34.179 27.581)"/>
              <circle className="glasshive-logo-dark-av" cx="5.847" cy="5.847" r="5.847" transform="translate(34.177 27.794)"/>
              <circle className="glasshive-logo-dark-aw" cx="5.847" cy="5.847" r="5.847" transform="translate(34.16 28.006)"/>
              <circle className="glasshive-logo-dark-ax" cx="5.847" cy="5.847" r="5.847" transform="translate(34.129 28.216)"/>
              <circle className="glasshive-logo-dark-ay" cx="5.847" cy="5.847" r="5.847" transform="translate(34.083 28.425)"/>
              <circle className="glasshive-logo-dark-az" cx="5.847" cy="5.847" r="5.847" transform="translate(34.022 28.63)"/>
              <circle className="glasshive-logo-dark-ba" cx="5.847" cy="5.847" r="5.847" transform="translate(33.947 28.831)"/>
              <circle className="glasshive-logo-dark-bb" cx="5.847" cy="5.847" r="5.847" transform="translate(33.857 29.027)"/>
              <circle className="glasshive-logo-dark-bc" cx="5.847" cy="5.847" r="5.847" transform="translate(33.752 29.218)"/>
              <circle className="glasshive-logo-dark-bd" cx="5.847" cy="5.847" r="5.847" transform="translate(33.631 29.402)"/>
              <circle className="glasshive-logo-dark-be" cx="5.847" cy="5.847" r="5.847" transform="translate(33.496 29.578)"/>
              <circle className="glasshive-logo-dark-bf" cx="5.847" cy="5.847" r="5.847" transform="translate(33.345 29.746)"/>
              <circle className="glasshive-logo-dark-bg" cx="5.847" cy="5.847" r="5.847" transform="translate(33.18 29.905)"/>
              <circle className="glasshive-logo-dark-bh" cx="5.847" cy="5.847" r="5.847" transform="translate(32.998 30.054)"/>
              <circle className="glasshive-logo-dark-bi" cx="5.847" cy="5.847" r="5.847" transform="translate(32.802 30.192)"/>
              <circle className="glasshive-logo-dark-bj" cx="5.847" cy="5.847" r="5.847" transform="translate(32.601 30.311)"/>
              <circle className="glasshive-logo-dark-bk" cx="5.847" cy="5.847" r="5.847" transform="translate(32.406 30.415)"/>
              <circle className="glasshive-logo-dark-bl" cx="5.847" cy="5.847" r="5.847" transform="translate(32.211 30.519)"/>
              <circle className="glasshive-logo-dark-bm" cx="5.847" cy="5.847" r="5.847" transform="translate(32.016 30.624)"/>
              <circle className="glasshive-logo-dark-bn" cx="5.847" cy="5.847" r="5.847" transform="translate(31.821 30.728)"/>
              <circle className="glasshive-logo-dark-bo" cx="5.847" cy="5.847" r="5.847" transform="translate(31.626 30.833)"/>
              <circle className="glasshive-logo-dark-bp" cx="5.847" cy="5.847" r="5.847" transform="translate(31.432 30.937)"/>
              <circle className="glasshive-logo-dark-bq" cx="5.847" cy="5.847" r="5.847" transform="translate(31.237 31.041)"/>
              <circle className="glasshive-logo-dark-br" cx="5.847" cy="5.847" r="5.847" transform="translate(31.042 31.146)"/>
              <circle className="glasshive-logo-dark-bs" cx="5.847" cy="5.847" r="5.847" transform="translate(30.847 31.25)"/>
              <circle className="glasshive-logo-dark-bt" cx="5.847" cy="5.847" r="5.847" transform="translate(30.652 31.354)"/>
              <circle className="glasshive-logo-dark-bu" cx="5.847" cy="5.847" r="5.847" transform="translate(30.458 31.459)"/>
              <circle className="glasshive-logo-dark-bv" cx="5.847" cy="5.847" r="5.847" transform="translate(30.263 31.563)"/>
              <circle className="glasshive-logo-dark-bw" cx="5.847" cy="5.847" r="5.847" transform="translate(30.068 31.668)"/>
              <circle className="glasshive-logo-dark-bx" cx="5.847" cy="5.847" r="5.847" transform="translate(29.873 31.772)"/>
              <circle className="glasshive-logo-dark-by" cx="5.847" cy="5.847" r="5.847" transform="translate(29.678 31.876)"/>
              <circle className="glasshive-logo-dark-bz" cx="5.847" cy="5.847" r="5.847" transform="translate(29.483 31.981)"/>
              <circle className="glasshive-logo-dark-ca" cx="5.847" cy="5.847" r="5.847" transform="translate(29.289 32.085)"/>
              <circle className="glasshive-logo-dark-cb" cx="5.847" cy="5.847" r="5.847" transform="translate(29.094 32.189)"/>
              <circle className="glasshive-logo-dark-cc" cx="5.847" cy="5.847" r="5.847" transform="translate(28.899 32.294)"/>
              <circle className="glasshive-logo-dark-cd" cx="5.847" cy="5.847" r="5.847" transform="translate(28.704 32.398)"/>
              <circle className="glasshive-logo-dark-ce" cx="5.847" cy="5.847" r="5.847" transform="translate(28.509 32.503)"/>
              <circle className="glasshive-logo-dark-cf" cx="5.847" cy="5.847" r="5.847" transform="translate(28.314 32.607)"/>
              <circle className="glasshive-logo-dark-cg" cx="5.847" cy="5.847" r="5.847" transform="translate(28.12 32.711)"/>
              <circle className="glasshive-logo-dark-ch" cx="5.847" cy="5.847" r="5.847" transform="translate(27.925 32.816)"/>
              <circle className="glasshive-logo-dark-ci" cx="5.847" cy="5.847" r="5.847" transform="translate(27.73 32.92)"/>
              <circle className="glasshive-logo-dark-cj" cx="5.847" cy="5.847" r="5.847" transform="translate(27.535 33.024)"/>
              <circle className="glasshive-logo-dark-ck" cx="5.847" cy="5.847" r="5.847" transform="translate(27.34 33.129)"/>
              <circle className="glasshive-logo-dark-cl" cx="5.847" cy="5.847" r="5.847" transform="translate(27.146 33.233)"/>
              <circle className="glasshive-logo-dark-cm" cx="5.847" cy="5.847" r="5.847" transform="translate(26.951 33.338)"/>
              <circle className="glasshive-logo-dark-cn" cx="5.847" cy="5.847" r="5.847" transform="translate(26.756 33.442)"/>
              <circle className="glasshive-logo-dark-co" cx="5.847" cy="5.847" r="5.847" transform="translate(26.561 33.546)"/>
              <circle className="glasshive-logo-dark-cp" cx="5.847" cy="5.847" r="5.847" transform="translate(26.366 33.651)"/>
              <circle className="glasshive-logo-dark-cq" cx="5.847" cy="5.847" r="5.847" transform="translate(26.171 33.755)"/>
              <circle className="glasshive-logo-dark-cr" cx="5.847" cy="5.847" r="5.847" transform="translate(25.977 33.859)"/>
              <circle className="glasshive-logo-dark-cs" cx="5.847" cy="5.847" r="5.847" transform="translate(25.782 33.964)"/>
              <circle className="glasshive-logo-dark-ct" cx="5.847" cy="5.847" r="5.847" transform="translate(25.587 34.068)"/>
              <circle className="glasshive-logo-dark-cu" cx="5.847" cy="5.847" r="5.847" transform="translate(25.392 34.173)"/>
              <circle className="glasshive-logo-dark-cv" cx="5.847" cy="5.847" r="5.847" transform="translate(25.197 34.277)"/>
              <circle className="glasshive-logo-dark-cw" cx="5.847" cy="5.847" r="5.847" transform="translate(25.002 34.381)"/>
              <circle className="glasshive-logo-dark-cx" cx="5.847" cy="5.847" r="5.847" transform="translate(24.808 34.486)"/>
              <circle className="glasshive-logo-dark-cy" cx="5.847" cy="5.847" r="5.847" transform="translate(24.613 34.59)"/>
              <circle className="glasshive-logo-dark-cz" cx="5.847" cy="5.847" r="5.847" transform="translate(24.418 34.694)"/>
              <circle className="glasshive-logo-dark-da" cx="5.847" cy="5.847" r="5.847" transform="translate(24.223 34.799)"/>
              <circle className="glasshive-logo-dark-db" cx="5.847" cy="5.847" r="5.847" transform="translate(24.028 34.903)"/>
              <circle className="glasshive-logo-dark-dc" cx="5.847" cy="5.847" r="5.847" transform="translate(23.834 35.008)"/>
              <circle className="glasshive-logo-dark-dd" cx="5.847" cy="5.847" r="5.847" transform="translate(23.639 35.112)"/>
              <circle className="glasshive-logo-dark-de" cx="5.847" cy="5.847" r="5.847" transform="translate(23.444 35.216)"/>
              <circle className="glasshive-logo-dark-df" cx="5.847" cy="5.847" r="5.847" transform="translate(23.249 35.321)"/>
              <circle className="glasshive-logo-dark-dg" cx="5.847" cy="5.847" r="5.847" transform="translate(23.054 35.425)"/>
              <circle className="glasshive-logo-dark-dh" cx="5.847" cy="5.847" r="5.847" transform="translate(22.859 35.529)"/>
              <circle className="glasshive-logo-dark-di" cx="5.847" cy="5.847" r="5.847" transform="translate(22.665 35.634)"/>
              <circle className="glasshive-logo-dark-dj" cx="5.847" cy="5.847" r="5.847" transform="translate(22.47 35.738)"/>
              <circle className="glasshive-logo-dark-dk" cx="5.847" cy="5.847" r="5.847" transform="translate(22.275 35.843)"/>
              <circle className="glasshive-logo-dark-dl" cx="5.847" cy="5.847" r="5.847" transform="translate(22.08 35.947)"/>
              <circle className="glasshive-logo-dark-dm" cx="5.847" cy="5.847" r="5.847" transform="translate(21.885 36.051)"/>
              <circle className="glasshive-logo-dark-dn" cx="5.847" cy="5.847" r="5.847" transform="translate(21.69 36.156)"/>
              <circle className="glasshive-logo-dark-do" cx="5.847" cy="5.847" r="5.847" transform="translate(21.496 36.26)"/>
              <circle className="glasshive-logo-dark-dp" cx="5.847" cy="5.847" r="5.847" transform="translate(21.301 36.364)"/>
              <circle className="glasshive-logo-dark-dq" cx="5.847" cy="5.847" r="5.847" transform="translate(21.106 36.469)"/>
              <circle className="glasshive-logo-dark-dr" cx="5.847" cy="5.847" r="5.847" transform="translate(20.911 36.573)"/>
              <circle className="glasshive-logo-dark-ds" cx="5.847" cy="5.847" r="5.847" transform="translate(20.716 36.678)"/>
              <circle className="glasshive-logo-dark-dt" cx="5.847" cy="5.847" r="5.847" transform="translate(20.522 36.782)"/>
              <circle className="glasshive-logo-dark-du" cx="5.847" cy="5.847" r="5.847" transform="translate(20.327 36.886)"/>
              <circle className="glasshive-logo-dark-dv" cx="5.847" cy="5.847" r="5.847" transform="translate(20.132 36.991)"/>
              <circle className="glasshive-logo-dark-dw" cx="5.847" cy="5.847" r="5.847" transform="translate(19.937 37.095)"/>
              <circle className="glasshive-logo-dark-dx" cx="5.847" cy="5.847" r="5.847" transform="translate(19.742 37.199)"/>
              <circle className="glasshive-logo-dark-dy" cx="5.847" cy="5.847" r="5.847" transform="translate(19.547 37.304)"/>
              <circle className="glasshive-logo-dark-dz" cx="5.847" cy="5.847" r="5.847" transform="translate(19.353 37.408)"/>
              <circle className="glasshive-logo-dark-ea" cx="5.847" cy="5.847" r="5.847" transform="translate(19.158 37.513)"/>
              <circle className="glasshive-logo-dark-eb" cx="5.847" cy="5.847" r="5.847" transform="translate(18.963 37.617)"/>
              <circle className="glasshive-logo-dark-ec" cx="5.847" cy="5.847" r="5.847" transform="translate(18.766 37.724)"/>
              <circle className="glasshive-logo-dark-ed" cx="5.847" cy="5.847" r="5.847" transform="translate(18.563 37.82)"/>
              <circle className="glasshive-logo-dark-ee" cx="5.847" cy="5.847" r="5.847" transform="translate(18.357 37.903)"/>
              <circle className="glasshive-logo-dark-ef" cx="5.847" cy="5.847" r="5.847" transform="translate(18.147 37.973)"/>
              <circle className="glasshive-logo-dark-eg" cx="5.847" cy="5.847" r="5.847" transform="translate(17.934 38.03)"/>
              <circle className="glasshive-logo-dark-eh" cx="5.847" cy="5.847" r="5.847" transform="translate(17.719 38.073)"/>
              <circle className="glasshive-logo-dark-ei" cx="5.847" cy="5.847" r="5.847" transform="translate(17.502 38.104)"/>
              <circle className="glasshive-logo-dark-ej" cx="5.847" cy="5.847" r="5.847" transform="translate(17.284 38.121)"/>
              <circle className="glasshive-logo-dark-ek" cx="5.847" cy="5.847" r="5.847" transform="translate(17.065 38.126)"/>
              <circle className="glasshive-logo-dark-el" cx="5.847" cy="5.847" r="5.847" transform="translate(16.847 38.117)"/>
              <circle className="glasshive-logo-dark-em" cx="5.847" cy="5.847" r="5.847" transform="translate(16.629 38.095)"/>
              <circle className="glasshive-logo-dark-en" cx="5.847" cy="5.847" r="5.847" transform="translate(16.413 38.06)"/>
              <circle className="glasshive-logo-dark-eo" cx="5.847" cy="5.847" r="5.847" transform="translate(16.198 38.011)"/>
              <circle className="glasshive-logo-dark-ep" cx="5.847" cy="5.847" r="5.847" transform="translate(15.986 37.95)"/>
              <circle className="glasshive-logo-dark-eq" cx="5.847" cy="5.847" r="5.847" transform="translate(15.778 37.875)"/>
              <circle className="glasshive-logo-dark-er" cx="5.847" cy="5.847" r="5.847" transform="translate(15.573 37.788)"/>
              <circle className="glasshive-logo-dark-es" cx="5.847" cy="5.847" r="5.847" transform="translate(15.372 37.687)"/>
              <circle className="glasshive-logo-dark-et" cx="5.847" cy="5.847" r="5.847" transform="translate(15.178 37.577)"/>
              <circle className="glasshive-logo-dark-eu" cx="5.847" cy="5.847" r="5.847" transform="translate(14.987 37.466)"/>
              <circle className="glasshive-logo-dark-ev" cx="5.847" cy="5.847" r="5.847" transform="translate(14.795 37.356)"/>
              <circle className="glasshive-logo-dark-ew" cx="5.847" cy="5.847" r="5.847" transform="translate(14.604 37.245)"/>
              <circle className="glasshive-logo-dark-ex" cx="5.847" cy="5.847" r="5.847" transform="translate(14.413 37.135)"/>
              <circle className="glasshive-logo-dark-ey" cx="5.847" cy="5.847" r="5.847" transform="translate(14.221 37.024)"/>
              <circle className="glasshive-logo-dark-ez" cx="5.847" cy="5.847" r="5.847" transform="translate(14.03 36.914)"/>
              <circle className="glasshive-logo-dark-fa" cx="5.847" cy="5.847" r="5.847" transform="translate(13.838 36.803)"/>
              <circle className="glasshive-logo-dark-fb" cx="5.847" cy="5.847" r="5.847" transform="translate(13.647 36.693)"/>
              <circle className="glasshive-logo-dark-fc" cx="5.847" cy="5.847" r="5.847" transform="translate(13.456 36.582)"/>
              <circle className="glasshive-logo-dark-fd" cx="5.847" cy="5.847" r="5.847" transform="translate(13.264 36.472)"/>
              <circle className="glasshive-logo-dark-fe" cx="5.847" cy="5.847" r="5.847" transform="translate(13.073 36.361)"/>
              <circle className="glasshive-logo-dark-ff" cx="5.847" cy="5.847" r="5.847" transform="translate(12.881 36.25)"/>
              <circle className="glasshive-logo-dark-fg" cx="5.847" cy="5.847" r="5.847" transform="translate(12.69 36.14)"/>
              <circle className="glasshive-logo-dark-fh" cx="5.847" cy="5.847" r="5.847" transform="translate(12.499 36.029)"/>
              <circle className="glasshive-logo-dark-fi" cx="5.847" cy="5.847" r="5.847" transform="translate(12.308 35.919)"/>
              <circle className="glasshive-logo-dark-fj" cx="5.847" cy="5.847" r="5.847" transform="translate(12.117 35.809)"/>
              <circle className="glasshive-logo-dark-fk" cx="5.847" cy="5.847" r="5.847" transform="translate(11.926 35.699)"/>
              <circle className="glasshive-logo-dark-fl" cx="5.847" cy="5.847" r="5.847" transform="translate(11.735 35.589)"/>
              <circle className="glasshive-logo-dark-fm" cx="5.847" cy="5.847" r="5.847" transform="translate(11.544 35.478)"/>
              <circle className="glasshive-logo-dark-fn" cx="5.847" cy="5.847" r="5.847" transform="translate(11.353 35.368)"/>
              <circle className="glasshive-logo-dark-fo" cx="5.847" cy="5.847" r="5.847" transform="translate(11.162 35.258)"/>
              <circle className="glasshive-logo-dark-fp" cx="5.847" cy="5.847" r="5.847" transform="translate(10.971 35.147)"/>
              <circle className="glasshive-logo-dark-fq" cx="5.847" cy="5.847" r="5.847" transform="translate(10.78 35.037)"/>
              <circle className="glasshive-logo-dark-fr" cx="5.847" cy="5.847" r="5.847" transform="translate(10.589 34.927)"/>
              <circle className="glasshive-logo-dark-fs" cx="5.847" cy="5.847" r="5.847" transform="translate(10.398 34.817)"/>
              <circle className="glasshive-logo-dark-ft" cx="5.847" cy="5.847" r="5.847" transform="translate(10.207 34.706)"/>
              <circle className="glasshive-logo-dark-fu" cx="5.847" cy="5.847" r="5.847" transform="translate(10.016 34.596)"/>
              <circle className="glasshive-logo-dark-fv" cx="5.847" cy="5.847" r="5.847" transform="translate(9.825 34.486)"/>
              <circle className="glasshive-logo-dark-fw" cx="5.847" cy="5.847" r="5.847" transform="translate(9.634 34.376)"/>
              <circle className="glasshive-logo-dark-fx" cx="5.847" cy="5.847" r="5.847" transform="translate(9.443 34.265)"/>
              <circle className="glasshive-logo-dark-fy" cx="5.847" cy="5.847" r="5.847" transform="translate(9.252 34.155)"/>
              <circle className="glasshive-logo-dark-fz" cx="5.847" cy="5.847" r="5.847" transform="translate(9.061 34.045)"/>
              <circle className="glasshive-logo-dark-ga" cx="5.847" cy="5.847" r="5.847" transform="translate(8.87 33.935)"/>
              <circle className="glasshive-logo-dark-gb" cx="5.847" cy="5.847" r="5.847" transform="translate(8.679 33.825)"/>
              <circle className="glasshive-logo-dark-gc" cx="5.847" cy="5.847" r="5.847" transform="translate(8.488 33.714)"/>
              <circle className="glasshive-logo-dark-gd" cx="5.847" cy="5.847" r="5.847" transform="translate(8.297 33.604)"/>
              <circle className="glasshive-logo-dark-ge" cx="5.847" cy="5.847" r="5.847" transform="translate(8.107 33.494)"/>
              <circle className="glasshive-logo-dark-gf" cx="5.847" cy="5.847" r="5.847" transform="translate(7.915 33.383)"/>
              <circle className="glasshive-logo-dark-gg" cx="5.847" cy="5.847" r="5.847" transform="translate(7.725 33.273)"/>
              <circle className="glasshive-logo-dark-gh" cx="5.847" cy="5.847" r="5.847" transform="translate(7.534 33.163)"/>
              <circle className="glasshive-logo-dark-gi" cx="5.847" cy="5.847" r="5.847" transform="translate(7.343 33.053)"/>
              <circle className="glasshive-logo-dark-gj" cx="5.847" cy="5.847" r="5.847" transform="translate(7.152 32.943)"/>
              <circle className="glasshive-logo-dark-gk" cx="5.847" cy="5.847" r="5.847" transform="translate(6.961 32.832)"/>
              <circle className="glasshive-logo-dark-gl" cx="5.847" cy="5.847" r="5.847" transform="translate(6.77 32.722)"/>
              <circle className="glasshive-logo-dark-gm" cx="5.847" cy="5.847" r="5.847" transform="translate(6.579 32.612)"/>
              <circle className="glasshive-logo-dark-gn" cx="5.847" cy="5.847" r="5.847" transform="translate(6.388 32.501)"/>
              <circle className="glasshive-logo-dark-go" cx="5.847" cy="5.847" r="5.847" transform="translate(6.197 32.391)"/>
              <circle className="glasshive-logo-dark-gp" cx="5.847" cy="5.847" r="5.847" transform="translate(6.006 32.281)"/>
              <circle className="glasshive-logo-dark-gq" cx="5.847" cy="5.847" r="5.847" transform="translate(5.815 32.171)"/>
              <circle className="glasshive-logo-dark-gr" cx="5.847" cy="5.847" r="5.847" transform="translate(5.624 32.061)"/>
              <circle className="glasshive-logo-dark-gs" cx="5.847" cy="5.847" r="5.847" transform="translate(5.433 31.95)"/>
              <circle className="glasshive-logo-dark-gt" cx="5.847" cy="5.847" r="5.847" transform="translate(5.242 31.84)"/>
              <circle className="glasshive-logo-dark-gu" cx="5.847" cy="5.847" r="5.847" transform="translate(5.051 31.73)"/>
              <circle className="glasshive-logo-dark-gv" cx="5.847" cy="5.847" r="5.847" transform="translate(4.86 31.619)"/>
              <circle className="glasshive-logo-dark-gw" cx="5.847" cy="5.847" r="5.847" transform="translate(4.669 31.509)"/>
              <circle className="glasshive-logo-dark-gx" cx="5.847" cy="5.847" r="5.847" transform="translate(4.478 31.399)"/>
              <circle className="glasshive-logo-dark-gy" cx="5.847" cy="5.847" r="5.847" transform="translate(4.287 31.289)"/>
              <circle className="glasshive-logo-dark-gz" cx="5.847" cy="5.847" r="5.847" transform="translate(4.096 31.179)"/>
              <circle className="glasshive-logo-dark-ha" cx="5.847" cy="5.847" r="5.847" transform="translate(3.905 31.068)"/>
              <circle className="glasshive-logo-dark-hb" cx="5.847" cy="5.847" r="5.847" transform="translate(3.714 30.958)"/>
              <circle className="glasshive-logo-dark-hc" cx="5.847" cy="5.847" r="5.847" transform="translate(3.523 30.848)"/>
              <circle className="glasshive-logo-dark-hd" cx="5.847" cy="5.847" r="5.847" transform="translate(3.333 30.737)"/>
              <circle className="glasshive-logo-dark-he" cx="5.847" cy="5.847" r="5.847" transform="translate(3.142 30.627)"/>
              <circle className="glasshive-logo-dark-hf" cx="5.847" cy="5.847" r="5.847" transform="translate(2.951 30.517)"/>
              <circle className="glasshive-logo-dark-hg" cx="5.847" cy="5.847" r="5.847" transform="translate(2.76 30.407)"/>
              <circle className="glasshive-logo-dark-hh" cx="5.847" cy="5.847" r="5.847" transform="translate(2.569 30.297)"/>
              <circle className="glasshive-logo-dark-hi" cx="5.847" cy="5.847" r="5.847" transform="translate(2.378 30.186)"/>
              <circle className="glasshive-logo-dark-hj" cx="5.847" cy="5.847" r="5.847" transform="translate(2.187 30.076)"/>
              <circle className="glasshive-logo-dark-hk" cx="5.847" cy="5.847" r="5.847" transform="translate(1.996 29.966)"/>
              <circle className="glasshive-logo-dark-hl" cx="5.847" cy="5.847" r="5.847" transform="translate(1.805 29.855)"/>
              <circle className="glasshive-logo-dark-hm" cx="5.847" cy="5.847" r="5.847" transform="translate(1.614 29.736)"/>
              <circle className="glasshive-logo-dark-hn" cx="5.847" cy="5.847" r="5.847" transform="translate(1.433 29.607)"/>
              <circle className="glasshive-logo-dark-ho" cx="5.847" cy="5.847" r="5.847" transform="translate(1.261 29.467)"/>
              <circle className="glasshive-logo-dark-hp" cx="5.847" cy="5.847" r="5.847" transform="translate(1.098 29.318)"/>
              <circle className="glasshive-logo-dark-hq" cx="5.847" cy="5.847" r="5.847" transform="translate(0.946 29.16)"/>
              <circle className="glasshive-logo-dark-hr" cx="5.847" cy="5.847" r="5.847" transform="translate(0.803 28.994)"/>
              <circle className="glasshive-logo-dark-hs" cx="5.847" cy="5.847" r="5.847" transform="translate(0.672 28.819)"/>
              <circle className="glasshive-logo-dark-ht" cx="5.847" cy="5.847" r="5.847" transform="translate(0.551 28.638)"/>
              <circle className="glasshive-logo-dark-hu" cx="5.847" cy="5.847" r="5.847" transform="translate(0.441 28.449)"/>
              <circle className="glasshive-logo-dark-hv" cx="5.847" cy="5.847" r="5.847" transform="translate(0.343 28.255)"/>
              <circle className="glasshive-logo-dark-hw" cx="5.847" cy="5.847" r="5.847" transform="translate(0.256 28.054)"/>
              <circle className="glasshive-logo-dark-hx" cx="5.847" cy="5.847" r="5.847" transform="translate(0.181 27.848)"/>
              <circle className="glasshive-logo-dark-hy" cx="5.847" cy="5.847" r="5.847" transform="translate(0.119 27.638)"/>
              <circle className="glasshive-logo-dark-hz" cx="5.847" cy="5.847" r="5.847" transform="translate(0.07 27.423)"/>
              <circle className="glasshive-logo-dark-ia" cx="5.847" cy="5.847" r="5.847" transform="translate(0.033 27.205)"/>
              <circle className="glasshive-logo-dark-ib" cx="5.847" cy="5.847" r="5.847" transform="translate(0.01 26.983)"/>
              <circle className="glasshive-logo-dark-ic" cx="5.847" cy="5.847" r="5.847" transform="translate(0 26.759)"/>
              <circle className="glasshive-logo-dark-id" cx="5.847" cy="5.847" r="5.847" transform="translate(0 26.538)"/>
              <circle className="glasshive-logo-dark-ie" cx="5.847" cy="5.847" r="5.847" transform="translate(0 26.317)"/>
              <circle className="glasshive-logo-dark-if" cx="5.847" cy="5.847" r="5.847" transform="translate(0 26.097)"/>
              <circle className="glasshive-logo-dark-ig" cx="5.847" cy="5.847" r="5.847" transform="translate(0 25.876)"/>
              <circle className="glasshive-logo-dark-ih" cx="5.847" cy="5.847" r="5.847" transform="translate(0 25.656)"/>
              <circle className="glasshive-logo-dark-ii" cx="5.847" cy="5.847" r="5.847" transform="translate(0 25.435)"/>
              <circle className="glasshive-logo-dark-ij" cx="5.847" cy="5.847" r="5.847" transform="translate(0 25.215)"/>
              <circle className="glasshive-logo-dark-ik" cx="5.847" cy="5.847" r="5.847" transform="translate(0 24.994)"/>
              <circle className="glasshive-logo-dark-il" cx="5.847" cy="5.847" r="5.847" transform="translate(0 24.774)"/>
              <circle className="glasshive-logo-dark-im" cx="5.847" cy="5.847" r="5.847" transform="translate(0 24.553)"/>
              <circle className="glasshive-logo-dark-in" cx="5.847" cy="5.847" r="5.847" transform="translate(0 24.333)"/>
              <circle className="glasshive-logo-dark-io" cx="5.847" cy="5.847" r="5.847" transform="translate(0 24.112)"/>
              <circle className="glasshive-logo-dark-ip" cx="5.847" cy="5.847" r="5.847" transform="translate(0 23.892)"/>
              <circle className="glasshive-logo-dark-iq" cx="5.847" cy="5.847" r="5.847" transform="translate(0 23.671)"/>
              <circle className="glasshive-logo-dark-ir" cx="5.847" cy="5.847" r="5.847" transform="translate(0 23.451)"/>
              <circle className="glasshive-logo-dark-is" cx="5.847" cy="5.847" r="5.847" transform="translate(0 23.23)"/>
              <circle className="glasshive-logo-dark-it" cx="5.847" cy="5.847" r="5.847" transform="translate(0 23.01)"/>
              <circle className="glasshive-logo-dark-iu" cx="5.847" cy="5.847" r="5.847" transform="translate(0 22.789)"/>
              <circle className="glasshive-logo-dark-iv" cx="5.847" cy="5.847" r="5.847" transform="translate(0 22.569)"/>
              <circle className="glasshive-logo-dark-iw" cx="5.847" cy="5.847" r="5.847" transform="translate(0 22.348)"/>
              <circle className="glasshive-logo-dark-ix" cx="5.847" cy="5.847" r="5.847" transform="translate(0 22.128)"/>
              <circle className="glasshive-logo-dark-iy" cx="5.847" cy="5.847" r="5.847" transform="translate(0 21.907)"/>
              <circle className="glasshive-logo-dark-iz" cx="5.847" cy="5.847" r="5.847" transform="translate(0 21.687)"/>
              <circle className="glasshive-logo-dark-ja" cx="5.847" cy="5.847" r="5.847" transform="translate(0 21.466)"/>
              <circle className="glasshive-logo-dark-jb" cx="5.847" cy="5.847" r="5.847" transform="translate(0 21.246)"/>
              <circle className="glasshive-logo-dark-jc" cx="5.847" cy="5.847" r="5.847" transform="translate(0 21.025)"/>
              <circle className="glasshive-logo-dark-jd" cx="5.847" cy="5.847" r="5.847" transform="translate(0 20.805)"/>
              <circle className="glasshive-logo-dark-je" cx="5.847" cy="5.847" r="5.847" transform="translate(0 20.584)"/>
              <circle className="glasshive-logo-dark-jf" cx="5.847" cy="5.847" r="5.847" transform="translate(0 20.364)"/>
              <circle className="glasshive-logo-dark-jg" cx="5.847" cy="5.847" r="5.847" transform="translate(0 20.143)"/>
              <circle className="glasshive-logo-dark-jh" cx="5.847" cy="5.847" r="5.847" transform="translate(0 19.923)"/>
              <circle className="glasshive-logo-dark-ji" cx="5.847" cy="5.847" r="5.847" transform="translate(0 19.702)"/>
              <circle className="glasshive-logo-dark-jj" cx="5.847" cy="5.847" r="5.847" transform="translate(0 19.482)"/>
              <circle className="glasshive-logo-dark-jk" cx="5.847" cy="5.847" r="5.847" transform="translate(0 19.261)"/>
              <circle className="glasshive-logo-dark-jl" cx="5.847" cy="5.847" r="5.847" transform="translate(0 19.041)"/>
              <circle className="glasshive-logo-dark-jm" cx="5.847" cy="5.847" r="5.847" transform="translate(0 18.82)"/>
              <circle className="glasshive-logo-dark-jn" cx="5.847" cy="5.847" r="5.847" transform="translate(0 18.6)"/>
              <circle className="glasshive-logo-dark-jo" cx="5.847" cy="5.847" r="5.847" transform="translate(0 18.379)"/>
              <circle className="glasshive-logo-dark-jp" cx="5.847" cy="5.847" r="5.847" transform="translate(0 18.159)"/>
              <circle className="glasshive-logo-dark-jq" cx="5.847" cy="5.847" r="5.847" transform="translate(0 17.938)"/>
              <circle className="glasshive-logo-dark-jr" cx="5.847" cy="5.847" r="5.847" transform="translate(0 17.718)"/>
              <circle className="glasshive-logo-dark-js" cx="5.847" cy="5.847" r="5.847" transform="translate(0 17.497)"/>
              <circle className="glasshive-logo-dark-jt" cx="5.847" cy="5.847" r="5.847" transform="translate(0 17.277)"/>
              <circle className="glasshive-logo-dark-ju" cx="5.847" cy="5.847" r="5.847" transform="translate(0 17.056)"/>
              <circle className="glasshive-logo-dark-jv" cx="5.847" cy="5.847" r="5.847" transform="translate(0 16.836)"/>
              <circle className="glasshive-logo-dark-jw" cx="5.847" cy="5.847" r="5.847" transform="translate(0 16.615)"/>
              <circle className="glasshive-logo-dark-jx" cx="5.847" cy="5.847" r="5.847" transform="translate(0 16.395)"/>
              <circle className="glasshive-logo-dark-jy" cx="5.847" cy="5.847" r="5.847" transform="translate(0 16.174)"/>
              <circle className="glasshive-logo-dark-jz" cx="5.847" cy="5.847" r="5.847" transform="translate(0 15.954)"/>
              <circle className="glasshive-logo-dark-ka" cx="5.847" cy="5.847" r="5.847" transform="translate(0 15.733)"/>
              <circle className="glasshive-logo-dark-kb" cx="5.847" cy="5.847" r="5.847" transform="translate(0 15.513)"/>
              <circle className="glasshive-logo-dark-kc" cx="5.847" cy="5.847" r="5.847" transform="translate(0 15.292)"/>
              <circle className="glasshive-logo-dark-kd" cx="5.847" cy="5.847" r="5.847" transform="translate(0 15.072)"/>
              <circle className="glasshive-logo-dark-ke" cx="5.847" cy="5.847" r="5.847" transform="translate(0 14.851)"/>
              <circle className="glasshive-logo-dark-kf" cx="5.847" cy="5.847" r="5.847" transform="translate(0 14.631)"/>
              <circle className="glasshive-logo-dark-kg" cx="5.847" cy="5.847" r="5.847" transform="translate(0 14.41)"/>
              <circle className="glasshive-logo-dark-kh" cx="5.847" cy="5.847" r="5.847" transform="translate(0 14.19)"/>
              <circle className="glasshive-logo-dark-ki" cx="5.847" cy="5.847" r="5.847" transform="translate(0 13.969)"/>
              <circle className="glasshive-logo-dark-kj" cx="5.847" cy="5.847" r="5.847" transform="translate(0 13.749)"/>
              <circle className="glasshive-logo-dark-kk" cx="5.847" cy="5.847" r="5.847" transform="translate(0 13.528)"/>
              <circle className="glasshive-logo-dark-kl" cx="5.847" cy="5.847" r="5.847" transform="translate(0 13.308)"/>
              <circle className="glasshive-logo-dark-km" cx="5.847" cy="5.847" r="5.847" transform="translate(0 13.087)"/>
              <circle className="glasshive-logo-dark-kn" cx="5.847" cy="5.847" r="5.847" transform="translate(0 12.867)"/>
              <circle className="glasshive-logo-dark-ko" cx="5.847" cy="5.847" r="5.847" transform="translate(0 12.646)"/>
              <circle className="glasshive-logo-dark-kp" cx="5.847" cy="5.847" r="5.847" transform="translate(0 12.426)"/>
              <circle className="glasshive-logo-dark-kq" cx="5.847" cy="5.847" r="5.847" transform="translate(0 12.205)"/>
              <circle className="glasshive-logo-dark-kr" cx="5.847" cy="5.847" r="5.847" transform="translate(0 11.985)"/>
              <circle className="glasshive-logo-dark-ks" cx="5.847" cy="5.847" r="5.847" transform="translate(0 11.764)"/>
              <circle className="glasshive-logo-dark-kt" cx="5.847" cy="5.847" r="5.847" transform="translate(0 11.544)"/>
              <circle className="glasshive-logo-dark-ku" cx="5.847" cy="5.847" r="5.847" transform="translate(0 11.323)"/>
              <circle className="glasshive-logo-dark-kv" cx="5.847" cy="5.847" r="5.847" transform="translate(0.001 11.101)"/>
              <circle className="glasshive-logo-dark-kw" cx="5.847" cy="5.847" r="5.847" transform="translate(0.011 10.877)"/>
              <circle className="glasshive-logo-dark-kx" cx="5.847" cy="5.847" r="5.847" transform="translate(0.036 10.656)"/>
              <circle className="glasshive-logo-dark-ky" cx="5.847" cy="5.847" r="5.847" transform="translate(0.073 10.438)"/>
              <circle className="glasshive-logo-dark-kz" cx="5.847" cy="5.847" r="5.847" transform="translate(0.123 10.224)"/>
              <circle className="glasshive-logo-dark-la" cx="5.847" cy="5.847" r="5.847" transform="translate(0.187 10.013)"/>
              <circle className="glasshive-logo-dark-lb" cx="5.847" cy="5.847" r="5.847" transform="translate(0.262 9.808)"/>
              <circle className="glasshive-logo-dark-lc" cx="5.847" cy="5.847" r="5.847" transform="translate(0.349 9.608)"/>
              <circle className="glasshive-logo-dark-ld" cx="5.847" cy="5.847" r="5.847" transform="translate(0.449 9.413)"/>
              <circle className="glasshive-logo-dark-le" cx="5.847" cy="5.847" r="5.847" transform="translate(0.559 9.226)"/>
              <circle className="glasshive-logo-dark-lf" cx="5.847" cy="5.847" r="5.847" transform="translate(0.681 9.044)"/>
              <circle className="glasshive-logo-dark-lg" cx="5.847" cy="5.847" r="5.847" transform="translate(0.813 8.871)"/>
              <circle className="glasshive-logo-dark-lh" cx="5.847" cy="5.847" r="5.847" transform="translate(0.956 8.705)"/>
              <circle className="glasshive-logo-dark-li" cx="5.847" cy="5.847" r="5.847" transform="translate(1.11 8.548)"/>
              <circle className="glasshive-logo-dark-lj" cx="5.847" cy="5.847" r="5.847" transform="translate(1.273 8.399)"/>
              <circle className="glasshive-logo-dark-lk" cx="5.847" cy="5.847" r="5.847" transform="translate(1.446 8.26)"/>
              <circle className="glasshive-logo-dark-ll" cx="5.847" cy="5.847" r="5.847" transform="translate(1.628 8.132)"/>
              <circle className="glasshive-logo-dark-lm" cx="5.847" cy="5.847" r="5.847" transform="translate(1.819 8.013)"/>
              <circle className="glasshive-logo-dark-ln" cx="5.846" cy="5.846" r="5.846" transform="translate(2.007 7.905)"/>
              <circle className="glasshive-logo-dark-lo" cx="5.846" cy="5.846" r="5.846" transform="translate(2.195 7.797)"/>
              <circle className="glasshive-logo-dark-lp" cx="5.846" cy="5.846" r="5.846" transform="translate(2.384 7.688)"/>
              <circle className="glasshive-logo-dark-lq" cx="5.846" cy="5.846" r="5.846" transform="translate(2.572 7.58)"/>
              <circle className="glasshive-logo-dark-lr" cx="5.845" cy="5.845" r="5.845" transform="translate(2.76 7.471)"/>
              <circle className="glasshive-logo-dark-ls" cx="5.845" cy="5.845" r="5.845" transform="translate(2.949 7.363)"/>
              <circle className="glasshive-logo-dark-lt" cx="5.845" cy="5.845" r="5.845" transform="translate(3.137 7.255)"/>
              <circle className="glasshive-logo-dark-lu" cx="5.845" cy="5.845" r="5.845" transform="translate(3.325 7.146)"/>
              <circle className="glasshive-logo-dark-lv" cx="5.844" cy="5.844" r="5.844" transform="translate(3.514 7.038)"/>
              <circle className="glasshive-logo-dark-lw" cx="5.844" cy="5.844" r="5.844" transform="translate(3.702 6.93)"/>
              <circle className="glasshive-logo-dark-lx" cx="5.844" cy="5.844" r="5.844" transform="translate(3.89 6.821)"/>
              <circle className="glasshive-logo-dark-ly" cx="5.844" cy="5.844" r="5.844" transform="translate(4.079 6.713)"/>
              <circle className="glasshive-logo-dark-lz" cx="5.844" cy="5.844" r="5.844" transform="translate(4.267 6.604)"/>
              <circle className="glasshive-logo-dark-ma" cx="5.843" cy="5.843" r="5.843" transform="translate(4.455 6.496)"/>
              <circle className="glasshive-logo-dark-mb" cx="5.843" cy="5.843" r="5.843" transform="translate(4.643 6.388)"/>
              <circle className="glasshive-logo-dark-mc" cx="5.843" cy="5.843" r="5.843" transform="translate(4.832 6.279)"/>
              <circle className="glasshive-logo-dark-md" cx="5.843" cy="5.843" r="5.843" transform="translate(5.02 6.171)"/>
              <circle className="glasshive-logo-dark-me" cx="5.843" cy="5.843" r="5.843" transform="translate(5.208 6.062)"/>
              <circle className="glasshive-logo-dark-mf" cx="5.843" cy="5.843" r="5.843" transform="translate(5.397 5.954)"/>
              <circle className="glasshive-logo-dark-mg" cx="5.842" cy="5.842" r="5.842" transform="translate(5.585 5.846)"/>
              <circle className="glasshive-logo-dark-mh" cx="5.842" cy="5.842" r="5.842" transform="translate(5.773 5.737)"/>
              <circle className="glasshive-logo-dark-mi" cx="5.842" cy="5.842" r="5.842" transform="translate(5.961 5.629)"/>
              <circle className="glasshive-logo-dark-mj" cx="5.842" cy="5.842" r="5.842" transform="translate(6.15 5.52)"/>
              <circle className="glasshive-logo-dark-mk" cx="5.842" cy="5.842" r="5.842" transform="translate(6.338 5.412)"/>
              <circle className="glasshive-logo-dark-ml" cx="5.841" cy="5.841" r="5.841" transform="translate(6.526 5.304)"/>
              <circle className="glasshive-logo-dark-mm" cx="5.841" cy="5.841" r="5.841" transform="translate(6.714 5.195)"/>
              <circle className="glasshive-logo-dark-mn" cx="5.841" cy="5.841" r="5.841" transform="translate(6.903 5.087)"/>
              <circle className="glasshive-logo-dark-mo" cx="5.841" cy="5.841" r="5.841" transform="translate(7.091 4.978)"/>
              <circle className="glasshive-logo-dark-mp" cx="5.841" cy="5.841" r="5.841" transform="translate(7.279 4.87)"/>
              <circle className="glasshive-logo-dark-mq" cx="5.841" cy="5.841" r="5.841" transform="translate(7.467 4.762)"/>
              <circle className="glasshive-logo-dark-mr" cx="5.84" cy="5.84" r="5.84" transform="translate(7.656 4.653)"/>
              <circle className="glasshive-logo-dark-ms" cx="5.84" cy="5.84" r="5.84" transform="translate(7.844 4.545)"/>
              <circle className="glasshive-logo-dark-mt" cx="5.84" cy="5.84" r="5.84" transform="translate(8.032 4.436)"/>
              <circle className="glasshive-logo-dark-mu" cx="5.84" cy="5.84" r="5.84" transform="translate(8.22 4.328)"/>
              <circle className="glasshive-logo-dark-mv" cx="5.84" cy="5.84" r="5.84" transform="translate(8.408 4.219)"/>
              <circle className="glasshive-logo-dark-mw" cx="5.84" cy="5.84" r="5.84" transform="translate(8.597 4.111)"/>
              <circle className="glasshive-logo-dark-mx" cx="5.84" cy="5.84" r="5.84" transform="translate(8.785 4.002)"/>
              <circle className="glasshive-logo-dark-my" cx="5.839" cy="5.839" r="5.839" transform="translate(8.973 3.894)"/>
              <circle className="glasshive-logo-dark-mz" cx="5.839" cy="5.839" r="5.839" transform="translate(9.161 3.785)"/>
              <circle className="glasshive-logo-dark-na" cx="5.839" cy="5.839" r="5.839" transform="translate(9.35 3.677)"/>
              <circle className="glasshive-logo-dark-nb" cx="5.839" cy="5.839" r="5.839" transform="translate(9.538 3.568)"/>
              <circle className="glasshive-logo-dark-nc" cx="5.839" cy="5.839" r="5.839" transform="translate(9.726 3.46)"/>
              <circle className="glasshive-logo-dark-nd" cx="5.839" cy="5.839" r="5.839" transform="translate(9.914 3.351)"/>
              <circle className="glasshive-logo-dark-ne" cx="5.839" cy="5.839" r="5.839" transform="translate(10.102 3.243)"/>
              <circle className="glasshive-logo-dark-nf" cx="5.839" cy="5.839" r="5.839" transform="translate(10.291 3.135)"/>
              <circle className="glasshive-logo-dark-ng" cx="5.838" cy="5.838" r="5.838" transform="translate(10.479 3.026)"/>
              <circle className="glasshive-logo-dark-nh" cx="5.838" cy="5.838" r="5.838" transform="translate(10.667 2.918)"/>
              <circle className="glasshive-logo-dark-ni" cx="5.838" cy="5.838" r="5.838" transform="translate(10.855 2.809)"/>
              <circle className="glasshive-logo-dark-nj" cx="5.838" cy="5.838" r="5.838" transform="translate(11.043 2.701)"/>
              <circle className="glasshive-logo-dark-nk" cx="5.838" cy="5.838" r="5.838" transform="translate(11.232 2.592)"/>
              <circle className="glasshive-logo-dark-nl" cx="5.838" cy="5.838" r="5.838" transform="translate(11.42 2.484)"/>
              <circle className="glasshive-logo-dark-nm" cx="5.838" cy="5.838" r="5.838" transform="translate(11.608 2.375)"/>
              <circle className="glasshive-logo-dark-nn" cx="5.838" cy="5.838" r="5.838" transform="translate(11.796 2.267)"/>
              <circle className="glasshive-logo-dark-no" cx="5.838" cy="5.838" r="5.838" transform="translate(11.984 2.158)"/>
              <circle className="glasshive-logo-dark-np" cx="5.838" cy="5.838" r="5.838" transform="translate(12.172 2.049)"/>
              <circle className="glasshive-logo-dark-nq" cx="5.838" cy="5.838" r="5.838" transform="translate(12.361 1.941)"/>
              <circle className="glasshive-logo-dark-nr" cx="5.838" cy="5.838" r="5.838" transform="translate(12.549 1.832)"/>
              <circle className="glasshive-logo-dark-ns" cx="5.837" cy="5.837" r="5.837" transform="translate(12.737 1.724)"/>
              <circle className="glasshive-logo-dark-nt" cx="5.837" cy="5.837" r="5.837" transform="translate(12.925 1.615)"/>
              <circle className="glasshive-logo-dark-nu" cx="5.837" cy="5.837" r="5.837" transform="translate(13.113 1.507)"/>
              <circle className="glasshive-logo-dark-nv" cx="5.837" cy="5.837" r="5.837" transform="translate(13.301 1.398)"/>
              <circle className="glasshive-logo-dark-nw" cx="5.837" cy="5.837" r="5.837" transform="translate(13.489 1.29)"/>
              <circle className="glasshive-logo-dark-nx" cx="5.837" cy="5.837" r="5.837" transform="translate(13.678 1.181)"/>
              <circle className="glasshive-logo-dark-ny" cx="5.837" cy="5.837" r="5.837" transform="translate(13.866 1.073)"/>
              <circle className="glasshive-logo-dark-nz" cx="5.837" cy="5.837" r="5.837" transform="translate(14.054 0.964)"/>
              <circle className="glasshive-logo-dark-oa" cx="5.837" cy="5.837" r="5.837" transform="translate(14.242 0.856)"/>
              <circle className="glasshive-logo-dark-ob" cx="5.837" cy="5.837" r="5.837" transform="translate(14.43 0.747)"/>
              <circle className="glasshive-logo-dark-oc" cx="5.837" cy="5.837" r="5.837" transform="translate(14.621 0.638)"/>
              <circle className="glasshive-logo-dark-od" cx="5.837" cy="5.837" r="5.837" transform="translate(14.817 0.536)"/>
              <circle className="glasshive-logo-dark-oe" cx="5.837" cy="5.837" r="5.837" transform="translate(15.016 0.443)"/>
              <circle className="glasshive-logo-dark-of" cx="5.837" cy="5.837" r="5.837" transform="translate(15.218 0.359)"/>
              <circle className="glasshive-logo-dark-og" cx="5.837" cy="5.837" r="5.837" transform="translate(15.423 0.283)"/>
              <circle className="glasshive-logo-dark-oh" cx="5.837" cy="5.837" r="5.837" transform="translate(15.63 0.217)"/>
              <circle className="glasshive-logo-dark-oi" cx="5.837" cy="5.837" r="5.837" transform="translate(15.838 0.159)"/>
              <circle className="glasshive-logo-dark-oj" cx="5.837" cy="5.837" r="5.837" transform="translate(16.049 0.11)"/>
              <circle className="glasshive-logo-dark-ok" cx="5.837" cy="5.837" r="5.837" transform="translate(16.261 0.071)"/>
              <circle className="glasshive-logo-dark-ol" cx="5.837" cy="5.837" r="5.837" transform="translate(16.474 0.04)"/>
              <circle className="glasshive-logo-dark-om" cx="5.837" cy="5.837" r="5.837" transform="translate(16.688 0.018)"/>
              <circle className="glasshive-logo-dark-on" cx="5.837" cy="5.837" r="5.837" transform="translate(16.902 0.004)"/>
              <circle className="glasshive-logo-dark-oo" cx="5.837" cy="5.837" r="5.837" transform="translate(17.117)"/>
              <circle className="glasshive-logo-dark-op" cx="5.837" cy="5.837" r="5.837" transform="translate(17.332 0.005)"/>
              <circle className="glasshive-logo-dark-oq" cx="5.837" cy="5.837" r="5.837" transform="translate(17.547 0.018)"/>
              <circle className="glasshive-logo-dark-or" cx="5.837" cy="5.837" r="5.837" transform="translate(17.761 0.04)"/>
              <circle className="glasshive-logo-dark-os" cx="5.837" cy="5.837" r="5.837" transform="translate(17.973 0.072)"/>
              <circle className="glasshive-logo-dark-ot" cx="5.837" cy="5.837" r="5.837" transform="translate(18.185 0.112)"/>
              <circle className="glasshive-logo-dark-ou" cx="5.837" cy="5.837" r="5.837" transform="translate(18.396 0.161)"/>
              <circle className="glasshive-logo-dark-ov" cx="5.837" cy="5.837" r="5.837" transform="translate(18.605 0.219)"/>
              <circle className="glasshive-logo-dark-ow" cx="5.837" cy="5.837" r="5.837" transform="translate(18.811 0.286)"/>
              <circle className="glasshive-logo-dark-ox" cx="5.837" cy="5.837" r="5.837" transform="translate(19.016 0.361)"/>
              <circle className="glasshive-logo-dark-oy" cx="5.837" cy="5.837" r="5.837" transform="translate(19.218 0.446)"/>
              <circle className="glasshive-logo-dark-oz" cx="5.837" cy="5.837" r="5.837" transform="translate(19.417 0.539)"/>
              <circle className="glasshive-logo-dark-pa" cx="5.837" cy="5.837" r="5.837" transform="translate(19.612 0.642)"/>
              <circle className="glasshive-logo-dark-pb" cx="5.837" cy="5.837" r="5.837" transform="translate(19.804 0.749)"/>
              <circle className="glasshive-logo-dark-pc" cx="5.837" cy="5.837" r="5.837" transform="translate(19.994 0.854)"/>
              <circle className="glasshive-logo-dark-pd" cx="5.837" cy="5.837" r="5.837" transform="translate(20.184 0.96)"/>
              <circle className="glasshive-logo-dark-pe" cx="5.837" cy="5.837" r="5.837" transform="translate(20.373 1.065)"/>
              <circle className="glasshive-logo-dark-pf" cx="5.837" cy="5.837" r="5.837" transform="translate(20.563 1.171)"/>
              <circle className="glasshive-logo-dark-pg" cx="5.837" cy="5.837" r="5.837" transform="translate(20.753 1.276)"/>
              <circle className="glasshive-logo-dark-ph" cx="5.837" cy="5.837" r="5.837" transform="translate(20.943 1.381)"/>
              <circle className="glasshive-logo-dark-pi" cx="5.837" cy="5.837" r="5.837" transform="translate(21.133 1.487)"/>
              <circle className="glasshive-logo-dark-pj" cx="5.837" cy="5.837" r="5.837" transform="translate(21.322 1.592)"/>
              <circle className="glasshive-logo-dark-pk" cx="5.837" cy="5.837" r="5.837" transform="translate(21.512 1.698)"/>
              <circle className="glasshive-logo-dark-pl" cx="5.837" cy="5.837" r="5.837" transform="translate(21.702 1.803)"/>
              <circle className="glasshive-logo-dark-pm" cx="5.838" cy="5.838" r="5.838" transform="translate(21.892 1.909)"/>
              <circle className="glasshive-logo-dark-pn" cx="5.838" cy="5.838" r="5.838" transform="translate(22.081 2.014)"/>
              <circle className="glasshive-logo-dark-po" cx="5.838" cy="5.838" r="5.838" transform="translate(22.271 2.119)"/>
              <circle className="glasshive-logo-dark-pp" cx="5.838" cy="5.838" r="5.838" transform="translate(22.461 2.225)"/>
              <circle className="glasshive-logo-dark-pq" cx="5.838" cy="5.838" r="5.838" transform="translate(22.651 2.33)"/>
              <circle className="glasshive-logo-dark-pr" cx="5.838" cy="5.838" r="5.838" transform="translate(22.841 2.436)"/>
              <circle className="glasshive-logo-dark-ps" cx="5.838" cy="5.838" r="5.838" transform="translate(23.03 2.541)"/>
              <circle className="glasshive-logo-dark-pt" cx="5.838" cy="5.838" r="5.838" transform="translate(23.22 2.646)"/>
              <circle className="glasshive-logo-dark-pu" cx="5.838" cy="5.838" r="5.838" transform="translate(23.41 2.752)"/>
              <circle className="glasshive-logo-dark-pv" cx="5.838" cy="5.838" r="5.838" transform="translate(23.6 2.857)"/>
              <circle className="glasshive-logo-dark-pw" cx="5.838" cy="5.838" r="5.838" transform="translate(23.789 2.963)"/>
              <circle className="glasshive-logo-dark-px" cx="5.838" cy="5.838" r="5.838" transform="translate(23.979 3.068)"/>
              <circle className="glasshive-logo-dark-py" cx="5.839" cy="5.839" r="5.839" transform="translate(24.169 3.173)"/>
              <circle className="glasshive-logo-dark-pz" cx="5.839" cy="5.839" r="5.839" transform="translate(24.359 3.279)"/>
              <circle className="glasshive-logo-dark-qa" cx="5.839" cy="5.839" r="5.839" transform="translate(24.548 3.384)"/>
              <circle className="glasshive-logo-dark-qb" cx="5.839" cy="5.839" r="5.839" transform="translate(24.738 3.489)"/>
              <circle className="glasshive-logo-dark-qc" cx="5.839" cy="5.839" r="5.839" transform="translate(24.928 3.595)"/>
              <circle className="glasshive-logo-dark-qd" cx="5.839" cy="5.839" r="5.839" transform="translate(25.118 3.7)"/>
              <circle className="glasshive-logo-dark-qe" cx="5.839" cy="5.839" r="5.839" transform="translate(25.307 3.805)"/>
              <circle className="glasshive-logo-dark-qf" cx="5.839" cy="5.839" r="5.839" transform="translate(25.497 3.911)"/>
              <circle className="glasshive-logo-dark-qg" cx="5.84" cy="5.84" r="5.84" transform="translate(25.687 4.016)"/>
              <circle className="glasshive-logo-dark-qh" cx="5.84" cy="5.84" r="5.84" transform="translate(25.876 4.121)"/>
              <circle className="glasshive-logo-dark-qi" cx="5.84" cy="5.84" r="5.84" transform="translate(26.066 4.227)"/>
              <circle className="glasshive-logo-dark-qj" cx="5.84" cy="5.84" r="5.84" transform="translate(26.256 4.332)"/>
              <circle className="glasshive-logo-dark-qk" cx="5.84" cy="5.84" r="5.84" transform="translate(26.446 4.438)"/>
              <circle className="glasshive-logo-dark-ql" cx="5.84" cy="5.84" r="5.84" transform="translate(26.635 4.543)"/>
              <circle className="glasshive-logo-dark-qm" cx="5.84" cy="5.84" r="5.84" transform="translate(26.825 4.648)"/>
              <circle className="glasshive-logo-dark-qn" cx="5.841" cy="5.841" r="5.841" transform="translate(27.015 4.754)"/>
              <circle className="glasshive-logo-dark-qo" cx="5.841" cy="5.841" r="5.841" transform="translate(27.204 4.859)"/>
              <circle className="glasshive-logo-dark-qp" cx="5.841" cy="5.841" r="5.841" transform="translate(27.394 4.964)"/>
              <circle className="glasshive-logo-dark-qq" cx="5.841" cy="5.841" r="5.841" transform="translate(27.584 5.069)"/>
              <circle className="glasshive-logo-dark-qr" cx="5.841" cy="5.841" r="5.841" transform="translate(27.773 5.175)"/>
              <circle className="glasshive-logo-dark-qs" cx="5.841" cy="5.841" r="5.841" transform="translate(27.963 5.28)"/>
              <circle className="glasshive-logo-dark-qt" cx="5.842" cy="5.842" r="5.842" transform="translate(28.153 5.385)"/>
              <circle className="glasshive-logo-dark-qu" cx="5.842" cy="5.842" r="5.842" transform="translate(28.342 5.491)"/>
              <circle className="glasshive-logo-dark-qv" cx="5.842" cy="5.842" r="5.842" transform="translate(28.532 5.596)"/>
              <circle className="glasshive-logo-dark-qw" cx="5.842" cy="5.842" r="5.842" transform="translate(28.722 5.701)"/>
              <circle className="glasshive-logo-dark-qx" cx="5.842" cy="5.842" r="5.842" transform="translate(28.911 5.807)"/>
              <circle className="glasshive-logo-dark-qy" cx="5.843" cy="5.843" r="5.843" transform="translate(29.101 5.912)"/>
              <circle className="glasshive-logo-dark-qz" cx="5.843" cy="5.843" r="5.843" transform="translate(29.291 6.017)"/>
              <circle className="glasshive-logo-dark-ra" cx="5.843" cy="5.843" r="5.843" transform="translate(29.48 6.123)"/>
              <circle className="glasshive-logo-dark-rb" cx="5.843" cy="5.843" r="5.843" transform="translate(29.67 6.228)"/>
              <circle className="glasshive-logo-dark-rc" cx="5.843" cy="5.843" r="5.843" transform="translate(29.86 6.333)"/>
              <circle className="glasshive-logo-dark-rd" cx="5.843" cy="5.843" r="5.843" transform="translate(30.049 6.438)"/>
              <circle className="glasshive-logo-dark-re" cx="5.844" cy="5.844" r="5.844" transform="translate(30.239 6.544)"/>
              <circle className="glasshive-logo-dark-rf" cx="5.844" cy="5.844" r="5.844" transform="translate(30.429 6.649)"/>
              <circle className="glasshive-logo-dark-rg" cx="5.844" cy="5.844" r="5.844" transform="translate(30.618 6.754)"/>
              <circle className="glasshive-logo-dark-rh" cx="5.844" cy="5.844" r="5.844" transform="translate(30.808 6.859)"/>
              <circle className="glasshive-logo-dark-ri" cx="5.844" cy="5.844" r="5.844" transform="translate(30.998 6.965)"/>
              <circle className="glasshive-logo-dark-rj" cx="5.845" cy="5.845" r="5.845" transform="translate(31.187 7.07)"/>
              <circle className="glasshive-logo-dark-rk" cx="5.845" cy="5.845" r="5.845" transform="translate(31.377 7.175)"/>
              <circle className="glasshive-logo-dark-rl" cx="5.845" cy="5.845" r="5.845" transform="translate(31.567 7.28)"/>
              <circle className="glasshive-logo-dark-rm" cx="5.845" cy="5.845" r="5.845" transform="translate(31.756 7.386)"/>
              <circle className="glasshive-logo-dark-rn" cx="5.846" cy="5.846" r="5.846" transform="translate(31.946 7.491)"/>
              <circle className="glasshive-logo-dark-ro" cx="5.846" cy="5.846" r="5.846" transform="translate(32.135 7.596)"/>
              <circle className="glasshive-logo-dark-rp" cx="5.846" cy="5.846" r="5.846" transform="translate(32.325 7.701)"/>
              <circle className="glasshive-logo-dark-rq" cx="5.846" cy="5.846" r="5.846" transform="translate(32.515 7.807)"/>
              <circle className="glasshive-logo-dark-rr" cx="5.847" cy="5.847" r="5.847" transform="translate(32.704 7.912)"/>
            </g>
            <g className="glasshive-logo-dark-rt" transform="matrix(1, 0, 0, 1, -3, -2)">
              <path className="glasshive-logo-dark-rs" d="M98.7,110.9a17.089,17.089,0,0,1-8.546-2.283L73.21,98.829a17.14,17.14,0,0,1-8.546-14.8V64.458a17.141,17.141,0,0,1,8.546-14.8L90.157,39.87a17.141,17.141,0,0,1,17.093,0L124.2,49.655a17.141,17.141,0,0,1,8.546,14.8V84.026a17.14,17.14,0,0,1-8.546,14.8l-16.947,9.784A17.089,17.089,0,0,1,98.7,110.9Zm0-69.489a13.269,13.269,0,0,0-6.636,1.772L75.121,52.963a13.31,13.31,0,0,0-6.636,11.494V84.026A13.31,13.31,0,0,0,75.121,95.52L92.068,105.3a13.311,13.311,0,0,0,13.272,0l16.947-9.784a13.31,13.31,0,0,0,6.636-11.494V64.458a13.31,13.31,0,0,0-6.636-11.494L105.34,43.179A13.27,13.27,0,0,0,98.7,41.406Z" transform="translate(-60.66 -34.59)"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default GlassHiveLogoDark
